"use client";

import { useContext, useEffect, useState } from "react";

import { analyticsContext } from "../lib";
import type { AnalyticsEvent } from "../types";
import { HallowAnalyticsEvent } from "../types";

export type UseViewedScreenProps = {
  screen: string;
  properties?: AnalyticsEvent["properties"];
  isReady?: boolean;
};

export const useViewedScreen = ({
  screen,
  properties = {},
  isReady = true,
}: UseViewedScreenProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const { track } = useContext(analyticsContext);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (isMounted && isReady)
      track({
        event: HallowAnalyticsEvent.ViewedScreen,
        properties: {
          screen_name: screen,
          ...properties,
        },
      });
  }, [isMounted, isReady]);
};
