import { z } from "zod";

import { imagesSchema } from "../imagesSchema";

export const nextUpSchema = z.object({
  type: z.literal("deeplink").or(z.string()),
  title: z.string(),
  image_url: z.string().url(),
  deeplink: z.string(),
  reason_code: z.enum([
    "first",
    "special_event",
    "routine",
    "community_challenge",
    "active_challenge",
    "active_campaign",
    "sunday",
    "night",
    "bible_in_a_year",
    "biy",
    "catechism_in_a_year",
    "ciy",
    "unfinished_daily",
    "unfinished_collection",
    "sunday_default_sermon",
    "random",
    "unknown",
  ]),
  reason_label: z.string(),
  color_hex: z.string(),
  has_access: z.boolean(),
  has_video: z.boolean(),
  description: z.string(),
  images: imagesSchema,
});

export type NextUp = z.infer<typeof nextUpSchema>;
