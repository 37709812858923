import { homeSchema } from "../../../lib";
import { request } from "../../../request";
import type { RequestProps } from "../../../request/request";

export type RequestHomeTrustedProps = Partial<
  Omit<RequestProps, "schema" | "url">
> & {
  date: string;
};

export const requestHomeTrusted = ({
  date,
  ...props
}: RequestHomeTrustedProps) =>
  request({
    ...props,
    next: { tags: [`home`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: homeSchema,
    urlBackendPath: `/trusted/home/${date}`,
  });
