import { useEffect, useState } from "react";

export const useLocationPathname = () => {
  const [visiblePath, setVisiblePath] = useState<string>("");

  const updatePath = () => {
    setVisiblePath(window.location.pathname);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setVisiblePath(window.location.pathname);
      window.addEventListener("popstate", updatePath);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("popstate", updatePath);
      }
    };
  }, []);

  return visiblePath;
};
