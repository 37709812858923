import type { Prayer } from "../../src";

const guides = [
  {
    deeplink: null,
    desc: "Abby is a founding member of Hallow, a wife, and a mother.",
    id: 1,
    image_url: "https://images.hallow.app/guides/abby_150.png",
    images: {
      large: null,
      medium: null,
      small: "https://images.hallow.app/guides/abby_150.png",
      fallback: "https://images.hallow.app/guides/abby_150.png",
    },
    name: "Abby",
    sample_audio_url: null,
    tagline: "Casual and Warm",
  },
  {
    deeplink: null,
    desc: "Francis is a founding member of Hallow and has five years of seminary formation.",
    id: 2,
    image_url: "https://images.hallow.app/guides/francis_150.png",
    images: {
      large: null,
      medium: null,
      small: "https://images.hallow.app/guides/francis_150.png",
      fallback: "https://images.hallow.app/guides/francis_150.png",
    },
    name: "Francis",
    sample_audio_url:
      "https://audio-dev.hallow.app/Samples%20-%20F.mp3?Expires=1708067526&Key-Pair-Id=APKAIG2AUVCADBSUVKSQ&Signature=HqJC8v7Cwfryem3ldUCj99LnwjgqkW6xLJVBwiKVlfWAbNSBl4v9E5xzJnKosiJppLkvkMM3BJ-zz6sLfCST8u%7E4n%7E3SGPt2hzcXI3LJL8AZNKewqi6hX1QnP8cMG4lUC-kZWsKJ8I%7ErSQnesGr%7ELwHlX3EgAxzFFwqRyQFhANClLuPb%7EFC3mqX0lwqqnwk2N62X8cxrDAkatm764CjJ9LHJ2K4QAG%7EcyB24y3hkonHtKYxHIkd1g2p8qQf2NjXQCFrH6uyH4KeSX6N-AGZyzDmlOmbNwX8FANpl1mUMhMsKnY8g65tSwuxUn%7EOV6lC6OIGbEpm-P5jX10iOGNhQBQ__",
    tagline: "Deliberate and Calm",
  },
  {
    deeplink: null,
    desc: 'Jonathan is a trained voice actor, who portrays Jesus in the hit TV series "The Chosen."',
    id: 3,
    image_url: "https://images.hallow.app/guides/jonathan_150.png",
    images: {
      large: null,
      medium: null,
      small: "https://images.hallow.app/guides/jonathan_150.png",
      fallback: "https://images.hallow.app/guides/jonathan_150.png",
    },
    name: "Jonathan",
    sample_audio_url: null,
    tagline: "Heartfelt and Engaging",
  },
];

const collection = {
  available_at: null,
  desc: "Join us this upcoming Lenten season as we follow Christ and deepen our relationship with Him. Stay tuned for exciting announcements about our Pray40 theme and the guests who'll be leading us in prayer and meditation.",
  ends_at: null,
  has_access: true,
  has_high_res_illo: true,
  has_joined: null,
  has_video: true,
  id: 1438,
  images: {
    color_hex: "#2B1232",
    large: "https://images.hallow.app/covers/1438_l.webp",
    fallback: "https://images.hallow.app/covers/1438_l.png",
    medium: null,
    small: null,
  },
  is_album: false,
  is_completed: false,
  paid: true,
  prayers_count: 47,
  sessions: "47 sessions",
  short_desc: "Lent Pray40 Challenge",
  supertitle: "CHALLENGES",
  title: "Lent Pray40",
  type: "collection",
};

export const mockDataPrayers: Prayer[] = [
  {
    challenge_id: null,
    collection,
    collection_id: 1438,
    detailed_desc: "Long, long, long, long description",
    guides,
    has_access: true,
    has_video: false,
    id: 1037204,
    images: collection.images,
    is_available: true,
    is_completed: false,
    is_download_enabled: true,
    is_favorite: false,
    is_redeemed: false,
    is_song: false,
    label_desc: "Short description",
    lengths: "5-10 minutes",
    order: 0,
    prompt: null,
    selected_guide_id: 1,
    selected_track_id: 94389,
    shareable_quote: null,
    shareable_url: "https://hallow.com/prayers/1",
    show_guide_selection: true,
    supertitle: "Super",
    title: "Prayer",
    tracks: [
      {
        duration: 300,
        guide_id: 2,
        has_access: true,
        has_transcript: false,
        id: 94389,
        length: "5 min",
        media_type: "audio",
        paid: false,
        prayer_id: 1037204,
      },
      {
        duration: 300,
        guide_id: 1,
        has_access: true,
        has_transcript: false,
        id: 94390,
        length: "5 min",
        media_type: "audio",
        paid: false,
        prayer_id: 1037204,
      },
      {
        duration: 300,
        guide_id: 3,
        has_access: true,
        has_transcript: false,
        id: 94391,
        length: "5 min",
        media_type: "audio",
        paid: false,
        prayer_id: 1037204,
      },
      {
        duration: 600,
        guide_id: 1,
        has_access: true,
        has_transcript: false,
        id: 94392,
        length: "10 min",
        media_type: "audio",
        paid: false,
        prayer_id: 1037204,
      },
      {
        duration: 600,
        guide_id: 2,
        id: 94393,
        length: "10 min",
        paid: false,
        prayer_id: 1037204,
        has_access: true,
        media_type: "audio",
        has_transcript: false,
      },
      {
        duration: 600,
        guide_id: 3,
        id: 94394,
        length: "10 min",
        paid: false,
        prayer_id: 1037204,
        has_access: true,
        media_type: "audio",
        has_transcript: false,
      },
      {
        duration: 900,
        guide_id: 1,
        id: 94395,
        length: "15 min",
        paid: false,
        prayer_id: 1037204,
        has_access: true,
        media_type: "audio",
        has_transcript: false,
      },
      {
        duration: 900,
        guide_id: 2,
        id: 94396,
        length: "15 min",
        paid: false,
        prayer_id: 1037204,
        has_access: true,
        media_type: "audio",
        has_transcript: false,
      },
      {
        duration: 900,
        guide_id: 3,
        id: 94397,
        length: "15 min",
        paid: false,
        prayer_id: 1037204,
        has_access: true,
        media_type: "audio",
        has_transcript: false,
      },
    ],
  },
  {
    id: 1037205,
    collection_id: 1438,
    detailed_desc: "Long, long, long, long description",
    has_video: true,
    is_available: true,
    is_download_enabled: true,
    is_song: false,
    label_desc: "Short description",
    lengths: "5-15 minutes",
    shareable_quote: null,
    shareable_url: "https://hallow.com/prayers/1",
    supertitle: "Super",
    title: "Prayer",
    order: 0,
    prompt: null,
    has_access: true,
    is_completed: false,
    is_favorite: false,
    is_redeemed: false,
    images: collection.images,
    guides,
    tracks: [
      {
        duration: 300,
        guide_id: 2,
        id: 84389,
        length: "5 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 300,
        guide_id: 1,
        id: 84390,
        length: "5 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 300,
        guide_id: 3,
        id: 84391,
        length: "5 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 600,
        guide_id: 1,
        id: 84392,
        length: "10 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 600,
        guide_id: 2,
        id: 84393,
        length: "10 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 600,
        guide_id: 3,
        id: 84384,
        length: "10 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 900,
        guide_id: 1,
        id: 84395,
        length: "15 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 900,
        guide_id: 2,
        id: 84396,
        length: "15 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 900,
        guide_id: 3,
        id: 84397,
        length: "15 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "audio",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 300,
        guide_id: 1,
        id: 85390,
        length: "5 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "video",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 300,
        guide_id: 3,
        id: 85391,
        length: "5 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "video",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 600,
        guide_id: 1,
        id: 85392,
        length: "10 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "video",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 600,
        guide_id: 3,
        id: 85385,
        length: "10 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "video",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 900,
        guide_id: 1,
        id: 85395,
        length: "15 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "video",
        has_access: true,
        has_transcript: false,
      },
      {
        duration: 900,
        guide_id: 3,
        id: 85397,
        length: "15 min",
        paid: false,
        prayer_id: 1037205,
        media_type: "video",
        has_access: true,
        has_transcript: false,
      },
    ],
    selected_guide_id: 1,
    selected_track_id: 84389,
    show_guide_selection: true,
    challenge_id: null,
    collection,
  },
];
