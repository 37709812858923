import type { BibleChapterUniqueId } from "../../../lib";
import { bibleDeeplinkSchema } from "../../../lib";
import { request } from "../../../request";

export type RequestBibleLinkedContentProps = {
  translationId: number;
  chapterId: BibleChapterUniqueId;
};

export const requestBibleLinkedContent = ({
  translationId,
  chapterId,
}: RequestBibleLinkedContentProps) =>
  request({
    method: "GET",
    next: { revalidate: 3600, tags: ["bible", "bible-deeplinks"] },
    requestType: "trusted",
    schema: bibleDeeplinkSchema,
    urlBackendPath: `/trusted/bible/${translationId}/content/${chapterId}`,
  });
