"use client";

import { useTranslations } from "@packages/i18n";
import { usePlayer } from "@packages/media";

import {
  ModalDropdownMenuHeader,
  ModalTabContent,
} from "../../../../../components";
import { SleepTimerTab, SleepTimerTabContent } from "../SleepTimer";

export const SelectTimerTabContent = () => {
  const player = usePlayer();
  const t = useTranslations();

  const options = [
    { seconds: 1800, label: t("sleep_length_minutes_format", { "0": 30 }) },
    { seconds: 3600, label: t("sleep_length_minutes_format", { "0": 60 }) },
    { seconds: 5400, label: t("sleep_length_minutes_format", { "0": 90 }) },
  ];

  if (
    player?.sleepTimerTarget &&
    player.sleepTimerTarget > 0 &&
    player.sleepTimerTarget > Date.now() &&
    player?.sleepTimerStart &&
    player.sleepTimerStart > 0
  ) {
    return (
      <SleepTimerTabContent
        key={"mediaMenu_selectTimerContent_active"}
        countdownSeconds={
          (player.sleepTimerTarget - player.sleepTimerStart) / 1000
        }
        value={`${Math.ceil((player.sleepTimerTarget - player.sleepTimerStart) / 1000)}_countdown`}
      />
    );
  }

  return (
    <>
      <ModalTabContent
        value={"timerSelectMenu"}
        header={
          <ModalDropdownMenuHeader>
            {t("track_settings_timer")}
          </ModalDropdownMenuHeader>
        }
      >
        {options.map((opt) => (
          <SleepTimerTab
            key={`mediaMenu_selectTimer_${opt.seconds}`}
            countdownSeconds={opt.seconds}
            timeMinStr={opt.label}
            value={`${opt.seconds}_countdown`}
          />
        ))}
      </ModalTabContent>
      {options.map((opt) => (
        <SleepTimerTabContent
          key={`mediaMenu_selectTimer_${opt.seconds}_content`}
          countdownSeconds={opt.seconds}
          value={`${opt.seconds}_countdown`}
        />
      ))}
    </>
  );
};
