import { z } from "zod";

import { oauthRefreshSchema } from "../../../types";

export const ottSchema = z.object({
  oauth: oauthRefreshSchema,
  redirect_path: z.string().url(),
});

export type OTTVerification = z.infer<typeof ottSchema>;
