import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const LEGACY_FONT_STYLE_KEY = "hlwFont";
export const COOKIE_KEY_FONT_STYLE = "hlwFontStyle";

export type DeleteCookieFontStyleProps = Omit<DeleteCookieProps, "key">;

export const deleteCookieFontStyle = ({
  cookies,
}: DeleteCookieFontStyleProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_FONT_STYLE, cookies });

export type GetCookieFontStyleProps = Omit<GetCookieProps, "key">;

export const getCookieFontStyle = ({
  cookies,
  schema,
}: GetCookieFontStyleProps = {}) =>
  getCookie({ key: COOKIE_KEY_FONT_STYLE, cookies, schema });

export type SetCookieFontStyleProps = Omit<SetCookieProps, "key">;

export const setCookieFontStyle = ({
  cookies,
  value,
  domain,
  options,
}: SetCookieFontStyleProps) =>
  setCookie({
    key: COOKIE_KEY_FONT_STYLE,
    cookies,
    value,
    domain,
    options,
  });
