"use client";

import type { CloudfrontCookie } from "@packages/sdk";
import type HLS from "hls.js";
import { forwardRef, useImperativeHandle, useRef } from "react";

import type { HallowElementProps } from "../../../../types";
import { useMediaLoader } from "../../../hooks";

declare global {
  interface Window {
    Hls: typeof HLS;
  }
}

export type AudioProps = HallowElementProps<"audio"> & {
  hlsUrl: string | null;
  policyCookie: CloudfrontCookie["CloudFront-Policy"];
  keyPairIdCookie: CloudfrontCookie["CloudFront-Key-Pair-Id"];
  signatureCookie: CloudfrontCookie["CloudFront-Signature"];
  regularUrl: string | null;
  type: "background" | "primary";
};

export type HallowAudioElement = { hls: HLS; element: HTMLAudioElement };

export const Audio = forwardRef<HallowAudioElement, AudioProps>(
  (
    {
      children,
      type,
      hlsUrl,
      policyCookie,
      keyPairIdCookie,
      signatureCookie,
      regularUrl,
      ...props
    },
    ref,
  ) => {
    const mediaRef = useRef<HTMLAudioElement>(null);

    useImperativeHandle(ref, () =>
      mediaRef.current
        ? {
            get hls() {
              return hls.current;
            },
            get element() {
              return mediaRef.current;
            },
          }
        : null,
    );

    const hls = useMediaLoader({
      mediaRef,
      hlsUrl,
      regularUrl,
      policyCookie,
      keyPairIdCookie,
      signatureCookie,
    });

    return (
      <audio
        key={`hallowAudioElement${type.toUpperCase()}`}
        id={`hallowAudioElement${type.toUpperCase()}`}
        ref={mediaRef}
        {...props}
      >
        {children}
      </audio>
    );
  },
);

Audio.displayName = "Audio";
