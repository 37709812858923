"use client";

import { useRouter } from "next/navigation";

import { URL_ACCESS } from "../lib";
import { useSession } from "../session";

export const useRedirects = () => {
  const { status } = useSession();
  const router = useRouter();

  const isAuthenticated = status === "authenticated";

  const redirectToSubscribe = async ({
    newWindow = false,
  }: { newWindow?: boolean } = {}) => {
    const url = isAuthenticated
      ? "/settings/subscription"
      : `${URL_ACCESS}/onboarding?page=subscribe&loginRedirect=${encodeURIComponent(window.location.href)}&subscribeRedirect=${encodeURIComponent(window.location.href)}`;
    if (newWindow) {
      window.open(url);
    } else {
      router.push(url);
    }
  };

  return { redirectToSubscribe };
};
