import { ottSchema } from "../../../lib";
import { request } from "../../../request";

export const requestOttVerification = (token: string) =>
  request({
    body: JSON.stringify({ token }),
    method: "POST",
    requestType: "default",
    schema: ottSchema,
    urlBackendPath: "/ott/verify",
  });
