import { z } from "zod";

import { meSchema } from "../../../../../lib";
import { oauthRefreshSchema } from "../../../../../types";

export const authSchema = z.object({
  oauth: oauthRefreshSchema,
  is_new: z.boolean(),
  user: meSchema,
});

export const booleanSuccessSchema = z.boolean();

export type Auth = z.TypeOf<typeof authSchema>;
