"use client";

import Bugsnag from "@bugsnag/js";
import type { BugsnagErrorBoundary as BugsnagBoundary } from "@bugsnag/plugin-react";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { useConsent } from "@packages/consent";
import { useRequestMe } from "@packages/sdk";
import type { ReactNode } from "react";
import React, { useEffect } from "react";

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
  enabledReleaseStages: ["production", "staging"],
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_ENV,
});

const BugsnagErrorBoundary: BugsnagBoundary =
  Bugsnag.getPlugin("react").createErrorBoundary(React);

export type ErrorsWrapperProps = { children: ReactNode };

export const ErrorsWrapper = ({ children }: ErrorsWrapperProps) => {
  const { isConsenting } = useConsent();
  const { query: queryMe } = useRequestMe();

  useEffect(() => {
    if (isConsenting && queryMe.data) {
      Bugsnag.setUser(
        queryMe.data.id.toString() ?? "",
        queryMe.data.email ?? "no email",
        queryMe.data.name ?? "no name",
      );
    } else {
      Bugsnag.pauseSession();
    }
  }, [isConsenting, queryMe?.data]);

  return <BugsnagErrorBoundary>{children}</BugsnagErrorBoundary>;
};
