import type {
  BaseHallowRequestProps,
  BibleContent,
  CloudfrontCookie,
} from "../../../lib";
import { bibleContentSchema, signCFUrl, URL_BIBLE } from "../../../lib";
import { validatePromiseSchema } from "../../../utils";

export interface BibleHTMLRequestProps extends BaseHallowRequestProps {
  translationId: number;
  bookId: string;
  chapter: number;
  cookies: Array<CloudfrontCookie>;
}

export const requestBibleHTML = async ({
  translationId,
  bookId,
  chapter,
  cookies,
}: BibleHTMLRequestProps): Promise<BibleContent> => {
  const htmlURL = signCFUrl(
    `${URL_BIBLE}/translations/${translationId}/${bookId}/${chapter}.html`,
    cookies,
  );
  const cssURL = signCFUrl(
    `${URL_BIBLE}/translations/${translationId}/${translationId}.css`,
    cookies,
  );

  const htmlPromise = fetch(htmlURL, {
    method: "GET",
    next: { tags: ["bible"] },
  } as RequestInit).then((htmlResponse) => {
    if (htmlResponse.ok) return htmlResponse.text();
    console.error("failed to fetch", htmlURL);
    throw htmlResponse;
  });
  const cssPromise = fetch(cssURL, {
    method: "GET",
    next: { tags: ["bible"] },
  } as RequestInit).then((cssResponse) => {
    if (cssResponse.ok) return cssResponse.text();
    console.error("failed to fetch", cssURL);
    throw cssResponse;
  });

  return validatePromiseSchema({
    promise: Promise.all([htmlPromise, cssPromise]).then(([html, css]) => ({
      html,
      css,
    })),
    schema: bibleContentSchema,
  }) as any as Promise<BibleContent>;
};
