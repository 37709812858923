import { useConsent } from "@packages/consent";
import { useTranslations } from "@packages/i18n";
import { useRequestAuth, uuid } from "@packages/sdk";
import AppleSignin from "react-apple-signin-auth";

import { Apple2Icon, Button, useAlert } from "../../_base";
import type { AuthSocialButtonProps } from "./AuthTypes";

export const AppleAuthButton = ({ onAuth }: AuthSocialButtonProps) => {
  const t = useTranslations();
  const alert = useAlert();
  const { mutationAppleAuth } = useRequestAuth();
  const { consent } = useConsent();
  const completeAppleAuth = async (res) => {
    const token = res.authorization.code;
    const name = res?.user?.name?.firstName ?? null;
    const last_name = res?.user?.name?.lastName ?? null;

    try {
      const auth = await mutationAppleAuth.mutateAsync({
        token,
        name,
        last_name,
        consent,
        redirect: process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI,
      });
      await onAuth?.("apple", auth);
    } catch (error) {
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  return (
    <AppleSignin
      authOptions={{
        clientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
        scope: process.env.NEXT_PUBLIC_APPLE_SCOPE,
        redirectURI: process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI,
        state: uuid(),
        usePopup: true,
      }}
      uiType="dark"
      onSuccess={(res) => completeAppleAuth(res)}
      onError={() => {
        alert.show({
          title: t("general_word_error"),
          description: t("login_social_auth_error_message"),
        });
      }}
      render={(props) => (
        <Button
          {...props}
          size="l"
          variant="neutral"
          startIcon={<Apple2Icon />}
          isFullWidth
        >
          {t("launch_continue_with_apple")}
        </Button>
      )}
    />
  );
};

AppleAuthButton.displayName = "AppleAuthButton";
