import type { Section } from "@packages/sdk";

import { SUPPORTED_ITEM_REFERENCE_TYPES } from "../../constants";

export function getSupportedSections(sections: Section[]) {
  const sectionsToDisplay = sections
    .map((section) => ({
      ...section,
      items: section.items.filter((item) =>
        SUPPORTED_ITEM_REFERENCE_TYPES.includes(item.reference_type),
      ),
    }))
    .filter((section) => section.items.length > 0);

  return sectionsToDisplay;
}
