import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const COOKIE_KEY_PREFERRED_THEME = "preferredTheme";

export type DeleteCookieThemeProps = Omit<DeleteCookieProps, "key">;

export const deleteCookieTheme = ({ cookies }: DeleteCookieThemeProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_PREFERRED_THEME, cookies });

export type GetCookieThemeProps = Omit<GetCookieProps, "key">;

export const getCookieTheme = ({ cookies, schema }: GetCookieThemeProps = {}) =>
  getCookie({ key: COOKIE_KEY_PREFERRED_THEME, cookies, schema });

export type SetCookieThemeProps = Omit<SetCookieProps, "key">;

export const setCookieTheme = ({
  cookies,
  value,
  domain,
  options,
}: SetCookieThemeProps) =>
  setCookie({
    key: COOKIE_KEY_PREFERRED_THEME,
    cookies,
    value,
    domain,
    options,
  });
