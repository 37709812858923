import { IconButton, ScreenSizeIcon } from "../../../../../components";
import type { WithButtonWrapper, WithStylexArray } from "../../../../../types";
import { useMediaClass } from "../../../../utils";
import { FragmentAsChild } from "../../../primitives";

export type GoFullscreenProps = WithStylexArray<
  WithButtonWrapper<{
    onFullscreen: any;
    onExitFullscreen: any;
    variant?: "transparentWhite" | "lighten";
  }>
>;

export const GoFullscreen = ({
  Wrapper = FragmentAsChild,
  onFullscreen,
  onExitFullscreen,
  styleXArray = [],
  variant = "transparentWhite",
}: GoFullscreenProps) => {
  const mediaClass = useMediaClass();
  return (
    <Wrapper asChild>
      <IconButton
        variant={variant}
        icon={
          mediaClass.includes("fullscreen") ? (
            <ScreenSizeIcon.Minimize />
          ) : (
            <ScreenSizeIcon.Maximize />
          )
        }
        onClick={
          mediaClass.includes("fullscreen") ? onExitFullscreen : onFullscreen
        }
        styleXArray={styleXArray}
      />
    </Wrapper>
  );
};
