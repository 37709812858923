"use client";

import type { CommunityType } from "../../../../lib";
import { getRequestNextPageParam } from "../../../../lib";
import type { CommunitiesChildren } from "../../../../lib/types/schema/community/children";
import { communitiesChildrenSchema } from "../../../../lib/types/schema/community/children";
import type { UseRequestInfiniteQueryProps } from "../../../../request";
import { useRequestInfiniteQuery } from "../../../../request";
import { keyCommunitiesChildren } from "./keyCommunitiesChildren";

export type UseRequestCommunitiesChildrenProps =
  UseRequestInfiniteQueryProps & {
    id: number;
    type: CommunityType;
  };

const REQUEST_COMMUNITIES_CHILDREN_PAGE_SIZE = 25;

export const useRequestCommunitiesChildren = ({
  id,
  type,
  ...props
}: UseRequestCommunitiesChildrenProps) => ({
  infiniteQuery: useRequestInfiniteQuery({
    getNextPageParam: (
      lastPage: CommunitiesChildren,
      _allPages: CommunitiesChildren[],
      lastPageParam: number,
    ) =>
      getRequestNextPageParam({
        total: lastPage.total,
        lastPageParam,
        pageSize: REQUEST_COMMUNITIES_CHILDREN_PAGE_SIZE,
      }),
    initialPageParam: 0,
    ...props,
    queryFnRequestProps: ({ pageParam }) => {
      return {
        schema: communitiesChildrenSchema,
        urlBackendPath: `/communities/${id}/children?page=${pageParam}&page_size=${REQUEST_COMMUNITIES_CHILDREN_PAGE_SIZE}&type=${type}`,
      };
    },
    queryKey: keyCommunitiesChildren({ id, type }),
  }),
});
