import { z } from "zod";

export const onboardingStepTypeEnum = z.enum([
  "name",
  "subscription",
  "hdyhau",
  "multi_select_grid",
  "single_select_text",
  "text",
  "notification_primer",
]);

export type OnboardingStepType = z.TypeOf<typeof onboardingStepTypeEnum>;
