import { pagesSchema } from "../../../lib";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export type RequestPagesTrustedProps = Omit<RequestProps, "url"> & {
  page: string;
};

export const requestPagesTrusted = ({
  page,
  ...props
}: RequestPagesTrustedProps) =>
  request({
    ...props,
    next: { tags: [`pages`, `pages-${page}`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: pagesSchema,
    urlBackendPath: `/trusted/pages/${page}`,
  });
