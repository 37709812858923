import { z } from "zod";

export const publicGivingSchema = z.object({
  id: z.number(),
  published_at: z.string().datetime(),
  goal_amount: z.number(),
  hallow_match: z
    .object({
      max_amount: z.number(),
      current_amount: z.number(),
      description: z.string(),
    })
    .nullable(),
  current_amount_of_all_successful_and_pending_payments: z.number(),
  current_total_raised_including_match: z.number(),
  number_of_donations: z.number(),
});

export const givingSchema = publicGivingSchema.extend({
  my_donations: z
    .object({
      status: z.enum([
        "created",
        "pending",
        "successful",
        "canceled",
        "failed",
        "unknown",
      ]),
      amount: z.number(),
      last_updated_at: z.string().datetime(),
    })
    .array()
    .optional(),
});

export type GivingDetails = z.infer<typeof givingSchema>;
