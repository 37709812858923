"use client";

import type { MutableRefObject } from "react";
import { useEffect, useRef } from "react";

const DEFAULT_OPTIONS = { attributes: true };

export const useMutationObserver = <T extends HTMLElement>(
  elRef: MutableRefObject<T | null>,
  callback: MutationCallback,
  options: MutationObserverInit = DEFAULT_OPTIONS,
) => {
  const observer = useRef<MutationObserver | null>(null);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const reps = useRef<number>(0);

  useEffect(() => {
    if (!observer.current && typeof MutationObserver !== "undefined") {
      observer.current = new MutationObserver(callback);
    }

    if (elRef.current) {
      observer.current?.observe(elRef.current, options);
    } else {
      interval.current = setInterval(() => {
        if (reps.current++ > 10 && interval.current)
          clearInterval(interval.current);
        if (elRef.current) {
          if (interval.current) clearInterval(interval.current);
          observer.current?.observe(elRef.current, options);
        }
      }, 50);
    }

    return () => {
      reps.current = 0;
      observer.current?.disconnect();
    };
  }, [observer.current, elRef.current, options]);
};
