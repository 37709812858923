"use client";

import { MediaElementContext } from "@packages/media";
import type { CloudfrontCookie } from "@packages/sdk";
import { signCFUrl } from "@packages/sdk";
import { addDays } from "date-fns";
import type HLS from "hls.js";
import type { Events } from "hls.js";
import type { MutableRefObject } from "react";
import { useCallback, useContext, useEffect, useRef } from "react";

export type UseMediaLoaderProps = {
  mediaRef: MutableRefObject<HTMLMediaElement>;
  hlsUrl: string;
  regularUrl: string;
  policyCookie: string;
  keyPairIdCookie: string;
  signatureCookie: string;
};

const hlsHasLoaded = () => typeof window !== "undefined" && !!window.Hls;

const setCookiesForIOS = (cookies: CloudfrontCookie) => {
  for (const [key, val] of Object.entries(cookies)) {
    document.cookie = `${key}=${val}; domain=hallow.com; path=/; SameSite=None; Secure; expires=${addDays(new Date(), 1).toUTCString()}`;
  }
};

export const useMediaLoader = ({
  mediaRef,
  hlsUrl,
  regularUrl,
  policyCookie,
  signatureCookie,
  keyPairIdCookie,
}: UseMediaLoaderProps): MutableRefObject<HLS | null> => {
  const player = useContext(MediaElementContext);

  useEffect(() => {
    callback();
  }, [hlsUrl, policyCookie, keyPairIdCookie, signatureCookie, regularUrl]);

  const hls = useRef<HLS | null>(
    hlsHasLoaded()
      ? new window.Hls({
          xhrSetup: (xhr, url) => {
            xhr.open("GET", signUrl(url));
          },
        })
      : null,
  );

  const signUrl = useCallback(
    (url: string) => {
      return signCFUrl(url, [
        {
          "CloudFront-Policy": policyCookie,
          "CloudFront-Key-Pair-Id": keyPairIdCookie,
          "CloudFront-Signature": signatureCookie,
        },
      ]);
    },
    [policyCookie, keyPairIdCookie, signatureCookie],
  );

  const source = () => {
    return mediaRef.current.src || mediaRef.current.currentSrc;
  };

  const callback = useCallback(() => {
    if (mediaRef.current && (hlsUrl || regularUrl)) {
      // don't load what's already loaded
      if (
        (hlsUrl && source().startsWith(hlsUrl) && !mediaRef.current?.error) ||
        (regularUrl && regularUrl === source())
      )
        return;

      if (hls.current) {
        hls.current.on<Events.ERROR>("hlsError" as Events.ERROR, (_, data) => {
          if (data.fatal) {
            player.onError(data.error);
            hls.current.detachMedia();
            mediaRef.current.setAttribute("src", regularUrl);
            mediaRef.current.load();
          }
        });
      }

      if (hlsUrl && window.Hls?.isSupported()) {
        hls.current?.loadSource(hlsUrl);
        hls.current?.attachMedia(mediaRef.current);
        hls.current?.startLoad();
      } else if (
        hlsUrl &&
        mediaRef.current.canPlayType("application/x-mpegURL") !== ""
      ) {
        setCookiesForIOS({
          "CloudFront-Policy": policyCookie,
          "CloudFront-Key-Pair-Id": keyPairIdCookie,
          "CloudFront-Signature": signatureCookie,
        });
        // native HLS support
        mediaRef.current.setAttribute("src", hlsUrl);
        mediaRef.current.load();
      } else if (regularUrl) {
        // no HLS support
        hls.current?.detachMedia();
        mediaRef.current.setAttribute("src", regularUrl);
        mediaRef.current.load();
      } else {
        hls.current?.detachMedia();
        mediaRef.current.removeAttribute("src");
        throw "Media not supported";
      }
    }
  }, [hlsUrl, signUrl, regularUrl, mediaRef?.current?.src]);

  return hls;
};
