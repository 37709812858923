import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const COOKIE_KEY_LETTER_SPACING = "hlwLetterSpacing";

export type DeleteCookieLetterSpacingProps = Omit<DeleteCookieProps, "key">;

export const deleteCookieLetterSpacing = ({
  cookies,
}: DeleteCookieLetterSpacingProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_LETTER_SPACING, cookies });

export type GetCookieLetterSpacingProps = Omit<GetCookieProps, "key">;

export const getCookieLetterSpacing = ({
  cookies,
  schema,
}: GetCookieLetterSpacingProps = {}) =>
  getCookie({ key: COOKIE_KEY_LETTER_SPACING, cookies, schema });

export type SetCookieLetterSpacingProps = Omit<SetCookieProps, "key">;

export const setCookieLetterSpacing = ({
  cookies,
  value,
  domain,
  options,
}: SetCookieLetterSpacingProps) =>
  setCookie({
    key: COOKIE_KEY_LETTER_SPACING,
    cookies,
    value,
    domain,
    options,
  });
