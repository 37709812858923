import type { Guide, Images, QueueManagedItem } from "@packages/sdk";

export const getContentId = (content: QueueManagedItem): number => {
  if (content?.type === "radio_song") {
    return content.radio_song.song_id;
  }
  return content?.prayer?.id;
};

export const getContentTitle = (content: QueueManagedItem): string => {
  if (content?.type === "radio_song") {
    return content.radio_song.title;
  }
  return content?.prayer?.title;
};

export const getContentImage = (content: QueueManagedItem): Images => {
  if (content?.type === "radio_song") {
    return content.radio_station.images;
  }
  return content?.collection?.images;
};

export const getContentDescription = (content: QueueManagedItem): string => {
  if (content?.type === "radio_song") {
    return content.radio_song.artist_name;
  }
  return content?.prayer?.label_desc;
};

export const getContentShareableLink = (content: QueueManagedItem): string => {
  if (content?.type === "radio_song") {
    return `https://hallow.com/radio-stations/${content.radio_station.id}`;
  }
  return content?.prayer?.shareable_url;
};

export const getContentArtist = (content: QueueManagedItem): string => {
  if (content?.type === "radio_song") {
    return content.radio_song.artist_name;
  }
  return content?.prayer?.guides?.find(
    (g) => g.id === content.selected_audio.guide_id,
  )?.name;
};

export const getContentAlbum = (content: QueueManagedItem): string => {
  if (content?.type === "radio_song") {
    return content.radio_song.album_name;
  }
  return content?.collection?.title;
};

export const getContentAlbumImage = (content: QueueManagedItem): Images => {
  if (content?.type === "radio_song") {
    return {
      large: content.radio_song.album_image_url,
      medium: null,
      small: null,
      fallback: content.radio_song.album_image_url,
    };
  }
  return content?.collection?.images;
};

export const getContentMetadata = (content: QueueManagedItem): string => {
  if (content?.type === "radio_song") {
    return content.radio_song.album_name;
  }
  return content?.prayer?.lengths;
};

export const getShareProperties = (
  content: QueueManagedItem,
  styleXArray?: any[],
): any => {
  const obj: any = {};
  if (content?.type === "radio_song") {
    obj.description = content.radio_station.short_desc;
    obj.title = content.radio_station.name;
  } else if (content) {
    obj.description = content.prayer.label_desc;
    obj.title = content.prayer.title;
  }
  const images = getContentImage(content);
  obj.color = images?.color_hex;
  obj.imgSrc = images?.fallback;
  obj.link = getContentShareableLink(content);
  obj.styleXArray = styleXArray;

  return obj;
};

export const getContentArtists = (content: QueueManagedItem): Guide[] => {
  if (content?.type === "radio_song") {
    return content.radio_song.artists;
  }
  return content?.prayer?.guides;
};

export const getContentHasVideo = (content: QueueManagedItem): boolean => {
  if (content?.type === "radio_song") return false;
  return content?.prayer?.has_video ?? false;
};
