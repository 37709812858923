"use client";

import { useRequestMutation } from "../../../request";

export const useRequestOnboarding = () => ({
  submitAnswer: useRequestMutation({
    mutationFnRequestProps: ({
      questionId,
      answers,
    }: {
      questionId: number;
      answers: number[];
    }) => ({
      body: JSON.stringify({ choices: answers }),
      urlBackendPath: `/poll/${questionId}`,
      method: "POST",
    }),
  }),
});
