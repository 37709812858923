import { setCookieHasRefreshToken } from "../../../lib";
import { useRequestMutation } from "../../../request";
import { useSession } from "../../../session";
import type { Auth } from "../auth";
import { authSchema, booleanSuccessSchema } from "../auth/types";

export type UTMProps = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
};

type ConsentProps = {
  consent: number[];
};

type GoogleAuthProps = {
  token: string;
  auth_flow: string;
} & UTMProps &
  ConsentProps;

type AppleAuthProps = {
  token: string;
  name?: string;
  last_name?: string;
  redirect?: string;
} & UTMProps &
  ConsentProps;

type SimpleAuthProps = (
  | {
      email: string;
      password: string;
      captcha: string;
    }
  | {
      phone: string;
      otp: string;
    }
) &
  UTMProps &
  ConsentProps;

export const useRequestAuth = () => {
  const { setStatus, setToken } = useSession();
  const onSuccess = (data: Auth) => {
    setCookieHasRefreshToken({ value: true });
    setToken(data.oauth.access_token);
    setStatus("authenticated");
    return new Promise((r) => setTimeout(r, 10));
  };

  return {
    mutationEmailExists: useRequestMutation({
      mutationFnRequestProps: (data: { email: string }) => ({
        body: JSON.stringify(data),
        method: "POST",
        urlBackendPath: "/exists",
        schema: booleanSuccessSchema,
        requestType: "default",
      }),
    }),
    mutationLogin: useRequestMutation({
      mutationFnRequestProps: (data: { email: string; password: string }) => ({
        body: JSON.stringify(data),
        method: "POST",
        urlBackendPath: "/login",
        schema: authSchema,
        requestType: "default",
      }),
      onSuccess,
    }),
    mutationRegister: useRequestMutation({
      mutationFnRequestProps: (data: SimpleAuthProps) => ({
        body: JSON.stringify(data),
        method: "POST",
        urlBackendPath: "/register",
        schema: authSchema,
        requestType: "default",
      }),
      onSuccess,
    }),
    mutationPhoneLogin: useRequestMutation({
      mutationFnRequestProps: (data: { phone: string; otp: string }) => ({
        body: JSON.stringify(data),
        method: "POST",
        urlBackendPath: "/login/phone",
        schema: authSchema,
        requestType: "default",
      }),
      onSuccess,
    }),
    mutationGoogleAuth: useRequestMutation({
      mutationFnRequestProps: (data: GoogleAuthProps) => ({
        body: JSON.stringify(data),
        method: "POST",
        urlBackendPath: "/social/google",
        schema: authSchema,
        requestType: "default",
      }),
      onSuccess,
    }),
    mutationAppleAuth: useRequestMutation({
      mutationFnRequestProps: (data: AppleAuthProps) => ({
        body: JSON.stringify(data),
        method: "POST",
        urlBackendPath: "/social/apple",
        schema: authSchema,
        requestType: "default",
      }),
      onSuccess,
    }),
    mutationForgotPassword: useRequestMutation({
      mutationFnRequestProps: (data: { email: string }) => ({
        body: JSON.stringify(data),
        method: "POST",
        urlBackendPath: "/password/forgot",
        schema: booleanSuccessSchema,
        requestType: "default",
      }),
    }),
  };
};
