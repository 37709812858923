"use client";

import type {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import type { z, ZodSchema } from "zod";

import { useSession } from "../../session";
import type { RequestProps } from "../request";
import { request } from "../request";
import { useHeadersValues } from "./useHeadersValues";

export type UseRequestInfiniteQueryProps<SchemaType extends ZodSchema = any> =
  Omit<Partial<UseInfiniteQueryOptions<z.TypeOf<SchemaType>>>, "queryFn"> & {
    enabledWithoutSessionToken?: boolean;
    queryFnRequestProps?:
      | RequestProps<SchemaType>
      | ((props: QueryFunctionContext) => RequestProps<SchemaType>);
  };

export const useRequestInfiniteQuery = <SchemaType extends ZodSchema = any>({
  enabled = true,
  enabledWithoutSessionToken = true,
  queryKey,
  queryFnRequestProps,
  ...props
}: UseRequestInfiniteQueryProps<SchemaType>) => {
  const headersValues = useHeadersValues();
  const { token, refresh } = useSession();

  return useInfiniteQuery({
    enabled:
      (enabledWithoutSessionToken ? true : typeof token === "string") &&
      enabled,
    queryFn: (props) =>
      request({
        ...props,
        headersValues: headersValues.current,
        refresh,
        ...(typeof queryFnRequestProps === "function"
          ? queryFnRequestProps(props)
          : queryFnRequestProps),
      }),
    queryKey: [queryKey, headersValues.current.locale],
    ...props,
  } as UseInfiniteQueryOptions<
    z.TypeOf<SchemaType>
  >) as UseInfiniteQueryResult<{
    pages: z.TypeOf<SchemaType>[];
    pageParams: number[];
  }>;
};
