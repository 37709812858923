export const locales = [
  "de",
  "en",
  "en-PH",
  "es",
  "fr",
  "it",
  "pl",
  "pt",
] as const;
