"use client";

import { useTranslations } from "@packages/i18n";
import { useCurrentGuide } from "@packages/media";

import { ModalTab, ProfileIcon } from "../../../../../components";
import type { MediaMenuTabProps } from "../MediaMenu";

export const SelectGuideTab = ({ disabled }: MediaMenuTabProps) => {
  const t = useTranslations();
  const { currentItem, guides } = useCurrentGuide();

  return (
    <ModalTab
      key={"guideSelectMenuTab"}
      value={"guideSelectMenu"}
      label={t("track_settings_guide")}
      startIcon={<ProfileIcon />}
      disabled={disabled}
    >
      {guides.find((g) => g.id === currentItem.selected_audio.guide_id)?.name ??
        t("general_word_error")}
    </ModalTab>
  );
};
