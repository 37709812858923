import { z } from "zod";

import { queueHlsObject } from "./queue/queueHlsObject";
import { trackSchema } from "./trackSchema";

export const playableMediaSchema = trackSchema
  .omit({ prayer_id: true })
  .extend({
    bg_sounds_enabled: z.boolean(),
    hls: queueHlsObject.nullable(),
    intro_ends_at: z.number().nullable(),
    pauses: z.number().array(),
    resumes_at: z.number().nullable(),
    resumes_session_id: z.string().uuid().nullable(),
    show_music_controls: z.boolean(),
    speed_changes_enabled: z.boolean(),
    url: z.string().nullable(),
    media_type: z.enum(["audio", "video"]).optional().nullable(),
    prayer_id: z.number().nullable(),
  });

export type PlayableMedia = z.infer<typeof playableMediaSchema>;
