"use client";

import {
  type Boolean,
  COOKIE_KEY_FONT_STYLE,
  COOKIE_KEY_LETTER_SPACING,
  COOKIE_KEY_PREFERRED_THEME,
  COOKIE_KEY_WORD_SPACING,
  genericBooleanSchema,
  getValidatedCookie,
  type LSFontFamily,
  lsFontFamilySchema,
  type LSTheme,
  lsThemeSchema,
} from "../lib";

export const useAccessibility = () => {
  const theme =
    getValidatedCookie<LSTheme>(
      COOKIE_KEY_PREFERRED_THEME,
      lsThemeSchema,
      true,
    ) ?? "match";
  const fontStyle =
    getValidatedCookie<LSFontFamily>(
      COOKIE_KEY_FONT_STYLE,
      lsFontFamilySchema,
      true,
    ) ?? "normal";
  const wordSpacing =
    getValidatedCookie<Boolean>(
      COOKIE_KEY_WORD_SPACING,
      genericBooleanSchema,
      true,
    ) ?? false;
  const letterSpacing =
    getValidatedCookie<Boolean>(
      COOKIE_KEY_LETTER_SPACING,
      genericBooleanSchema,
      true,
    ) ?? false;

  return {
    preferredTheme: theme,
    hlwFontStyle: fontStyle,
    hlwLetterSpacing: letterSpacing ? "expanded" : "default",
    hlwWordSpacing: wordSpacing ? "expanded" : "default",
  };
};
