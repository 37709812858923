"use client";

import { z } from "zod";

import type { UseRequestMutationProps } from "../../../request";
import { useRequestMutation } from "../../../request";

export type UseRequestExistsProps = UseRequestMutationProps;

export const useRequestExists = ({ ...props }: UseRequestExistsProps = {}) => ({
  ...props,
  mutationPost: useRequestMutation({
    mutationFnRequestProps: (data: { email: string }) => ({
      body: JSON.stringify(data),
      method: "POST",
      requestType: "default",
      schema: z.boolean(),
      urlBackendPath: "/exists",
    }),
  }),
});
