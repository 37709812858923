import type { RequestProps } from "../../../request";
import { request } from "../../../request";
import { loginSchema } from "./types";

export type RequestLoginProps = Omit<RequestProps, "url">;

export const requestLogin = ({ ...props }: RequestLoginProps = {}) =>
  request({
    ...props,
    method: "POST",
    requestType: "default",
    schema: loginSchema,
    urlBackendPath: "/login",
  });
