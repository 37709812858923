import type { Locale } from "../../../i18n";
import type { BaseKeyFunction } from "../../../lib";
import type { SearchItemType } from "./types";

export type KeySearchProps = {
  filters: {
    locale: Locale;
    type?: SearchItemType;
  };
  search: string;
};

export const keySearch: BaseKeyFunction<KeySearchProps> = ({
  filters,
  search,
}) => ["search", filters, search];
