"use client";

import { useTranslations } from "@packages/i18n";

import {
  ModalDropdownMenuHeader,
  ModalTabContent,
} from "../../../../../components";
import { SelectLengthContent } from "./SelectLengthContent";

export const SelectLengthTabContent = () => {
  const t = useTranslations();

  return (
    <ModalTabContent
      value={"lengthSelectMenu"}
      header={
        <ModalDropdownMenuHeader>
          {t("track_settings_length")}
        </ModalDropdownMenuHeader>
      }
    >
      <SelectLengthContent />
    </ModalTabContent>
  );
};
