"use client";

import { useQueryClient } from "@tanstack/react-query";
import { useLocale } from "next-intl";

import type { FamilyInvite, User } from "../../../lib";
import { familySchema } from "../../../lib";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyFamily } from "./keyFamily";
import { keySubscription } from "./keySubscriptions";

export const useRequestFamily = () => {
  const queryClient = useQueryClient();
  const locale = useLocale();

  return {
    query: useRequestQuery({
      queryFnRequestProps: {
        schema: familySchema,
        urlBackendPath: "/family",
      },
      queryKey: keyFamily(),
    }),
    mutationAddMember: useRequestMutation({
      mutationFnRequestProps: (data: Pick<FamilyInvite, "email">) => ({
        urlBackendPath: "/family/users",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    mutationRemoveMember: useRequestMutation({
      mutationFnRequestProps: ({ id }: Pick<User, "id">) => ({
        urlBackendPath: `/family/users/${id}`,
        method: "DELETE",
      }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [keySubscription(), locale],
        });
      },
    }),
    mutationCancelInvite: useRequestMutation({
      mutationFnRequestProps: ({ id }: Pick<FamilyInvite, "id">) => ({
        urlBackendPath: `/family/invites/${id}`,
        method: "DELETE",
      }),
    }),
    mutationJoin: useRequestMutation({
      mutationFnRequestProps: ({ code }: Pick<FamilyInvite, "code">) => ({
        urlBackendPath: `/families/${code}/users`,
        method: "POST",
      }),
    }),
  };
};
