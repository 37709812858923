import { z } from "zod";

import { meSchema } from "../../../../../lib";
import { sessionRefreshSchema } from "../../../../../session";
import { onboardingStepSchema } from "./onboardingStepSchema.schema";

export const loginSchema = z.object({
  is_attributed: z.boolean(),
  is_new: z.boolean(),
  oauth: sessionRefreshSchema,
  onboarding: z.any().array(),
  onboarding_steps: onboardingStepSchema.array(),
  onboarding_flow: z.any(),
  user: meSchema,
});

export type Login = z.TypeOf<typeof loginSchema>;
