"use client";

import { useTranslations } from "@packages/i18n";
import { usePlayer } from "@packages/media";
import type { Prayer, Track } from "@packages/sdk";
import {
  redirectToAuth,
  useRedirects,
  useRequestMe,
  useSession,
} from "@packages/sdk";
import { Button, PlayIcon, SubscriptionIcon } from "@packages/ui";
import { Suspense } from "react";

export type PrayerPlayButtonProps = {
  prayer: Prayer;
  track: Pick<Track, "id" | "paid">;
  isSong?: boolean;
};

export const PrayerPlayButton = ({
  prayer,
  track,
  isSong,
}: PrayerPlayButtonProps) => {
  const t = useTranslations();
  const { status } = useSession();
  const player = usePlayer();
  const { query: user } = useRequestMe();
  const { redirectToSubscribe } = useRedirects();

  if (!track) {
    return null;
  }

  const needsToSubscribeToListen =
    !Boolean(prayer?.has_access) ||
    (track.paid && !Boolean(user?.data?.subscription));

  const playSession = async () => {
    if (status === "authenticated") {
      if (needsToSubscribeToListen) {
        redirectToSubscribe();
      } else {
        // it's possible someone reached this page via deeplink or manual URL editing, so it could still be a song
        // page. We need to act appropriately.
        if (isSong) {
          await player?.playNew({
            type: "album",
            id: prayer.collection_id,
            // these are only used for albums
            target: prayer.id,
          });
        } else {
          await player?.playNew({ type: "audio", id: track.id });
        }
      }
    } else {
      redirectToAuth();
    }
  };

  return (
    <Suspense>
      <Button
        isFullWidth={true}
        onClickCapture={playSession}
        size="l"
        startIcon={
          needsToSubscribeToListen ? (
            <SubscriptionIcon.Filled />
          ) : (
            <PlayIcon.Small />
          )
        }
        variant={"secondary"}
      >
        {t(
          needsToSubscribeToListen
            ? "button_subscribe_to_listen"
            : status === "authenticated"
              ? "play_session"
              : "button_listen_for_free",
        )}
      </Button>
    </Suspense>
  );
};
