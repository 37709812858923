"use client";

import type { ComponentPropsWithoutRef } from "react";
import { useEffect, useState } from "react";

import { getCookieHasRefreshToken, setCookieHasRefreshToken } from "../../lib";
import type { OauthRefresh } from "../../types";
import { parseBoolean } from "../../utils";
import { sessionContext } from "../sessionContext";
import type { SessionStatus } from "../types";
import {
  requestRefreshTokenDelete,
  requestSessionLogout,
  requestSessionRefresh,
} from "../utils";

export type SessionProviderProps = Omit<
  ComponentPropsWithoutRef<typeof sessionContext.Provider>,
  "value"
>;

export const SessionProvider = ({
  children,
  ...props
}: SessionProviderProps) => {
  const hasRefreshToken = parseBoolean({ string: getCookieHasRefreshToken() });

  const [status, setStatus] = useState<SessionStatus>("loading");
  const [token, setToken] = useState<null | string>(null);

  const logout = ({ callback }: { callback?: Function } = {}) =>
    requestSessionLogout({
      headers: { Authorization: `Bearer ${token}` },
    }).then(async () => {
      setCookieHasRefreshToken({ value: false });
      setStatus("unauthenticated");
      setToken(null);

      if (callback) await callback();
    });

  const refresh = ({ callback }: { callback?: Function } = {}) =>
    requestSessionRefresh()
      .then(async (data) => {
        if (data.access_token) {
          setStatus("authenticated");
          setToken(data.access_token);

          if (callback) await callback(data);
          return data;
        }
      })
      .catch(async () => {
        await requestRefreshTokenDelete().then((data) => {
          if (data) {
            setCookieHasRefreshToken({ value: false });
            location.reload();
          }
        });
        return {} as OauthRefresh;
      });

  useEffect(() => {
    if (hasRefreshToken) {
      if (!token) refresh();
      else setStatus("authenticated");
    } else setStatus("unauthenticated");
  }, [hasRefreshToken]);

  return (
    <sessionContext.Provider
      value={{
        hasRefreshToken,
        logout,
        refresh,
        setStatus,
        setToken,
        status,
        token,
      }}
      {...props}
    >
      {children}
    </sessionContext.Provider>
  );
};
