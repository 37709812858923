"use client";

import { useSession } from "@packages/sdk";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { AuthMainFlow } from "./AuthMainFlow";
import type { AuthFlow, AuthProps } from "./AuthTypes";
import { EmailAuthFlow } from "./EmailAuthFlow";
import { PasswordAuthFlow } from "./PasswordAuthFlow";
import { PhoneAuthFlow } from "./PhoneAuthFlow";
import { PhoneCodeAuthFlow } from "./PhoneCodeAuthFlow";

type AuthComponentProps = AuthProps & {
  onFlowChange?: (flow: AuthFlow) => void;
};

export const Auth = ({
  onFlowChange: handleFlowChange,
  allowedMethods = ["email", "phone", "apple", "google"],
  ...props
}: AuthComponentProps) => {
  const initialFlow = props.email ? "email" : props.phone ? "phone" : "main";
  const [flow, setFlow] = useState<AuthFlow>(initialFlow);
  const [state, setState] = useState<Record<string, any>>({});
  const { status, token } = useSession();

  useEffect(() => {
    // on initial load only, don't interfere with regular operations by putting status in the dependency array
    if (status === "authenticated") {
      // auth method is unknown
      props.onAuth?.(null, { oauth: { access_token: token } });
    }
  }, []);

  const onFlowChange = (flow: AuthFlow) => {
    setFlow(flow);
    handleFlowChange?.(flow);
  };

  const flowProps = {
    ...props,
    allowedMethods,
    flow,
    setFlow: onFlowChange,
    state,
    setState,
  };

  const flows: Record<AuthFlow, ReactNode> = {
    main: <AuthMainFlow {...flowProps} />,
    email: <EmailAuthFlow {...flowProps} />,
    password: <PasswordAuthFlow {...flowProps} />,
    phone: <PhoneAuthFlow {...flowProps} />,
    phone_code: <PhoneCodeAuthFlow {...flowProps} />,
  };

  return flows[flow];
};

Auth.displayName = "Auth";
