"use client";

import type { ReactNode } from "react";
import { createContext, useEffect, useState } from "react";
import { VisuallyHidden } from "react-aria";

import { useDynamicViewport } from "../../../../hooks";
import { VIEWPORT_TABLET } from "../../../../lib";
import type { StyleXArray } from "../../../../types";
import type { DropdownMenuProps } from "../DropdownMenu";
import { DropdownMenu } from "../DropdownMenu";
import type { ModalProps } from "../Modal";
import { Modal } from "../Modal";
import { ModalHeader } from "../ModalHeader";

export type ModalDropdownMenuContextShape = {
  close: () => void;
};

export const ModalDropdownMenuContext =
  createContext<ModalDropdownMenuContextShape>({ close: () => {} });

export type ModalDropdownMenuProps = {
  children?: ReactNode;
  closeOnScroll?: boolean;
  contentStyleXArray?: StyleXArray;
  dropdownMenuProps?: DropdownMenuProps;
  modalProps?: ModalProps;
  trigger?: ReactNode;
  minDesktopWidth?: number;
  open?: boolean;
  onOpenChange?: (newOpen: boolean) => void;
};

export const ModalDropdownMenu = ({
  children,
  closeOnScroll = false,
  contentStyleXArray = [],
  dropdownMenuProps = {},
  modalProps = {},
  trigger,
  minDesktopWidth = VIEWPORT_TABLET,
  open,
  onOpenChange,
}: ModalDropdownMenuProps) => {
  const isDesktopViewport = useDynamicViewport({
    minimumWidth: minDesktopWidth,
    defaultState: null,
  });
  const [localOpen, setLocalOpen] = useState(false);

  useEffect(() => {
    if (localOpen !== open) {
      onOpenChange?.(localOpen);
    }
  }, [localOpen]);

  useEffect(() => {
    if (open !== localOpen) {
      setLocalOpen(open);
    }
  }, [open]);

  if (isDesktopViewport === null) return null;

  if (isDesktopViewport) {
    return (
      <ModalDropdownMenuContext.Provider
        value={{ close: () => setLocalOpen(false) }}
      >
        <DropdownMenu
          closeOnScroll={closeOnScroll}
          contentStyleXArray={contentStyleXArray}
          open={localOpen}
          onOpenChange={setLocalOpen}
          trigger={trigger}
          {...dropdownMenuProps}
        >
          {dropdownMenuProps.children || children}
        </DropdownMenu>
      </ModalDropdownMenuContext.Provider>
    );
  }

  return (
    <ModalDropdownMenuContext.Provider
      value={{ close: () => setLocalOpen(false) }}
    >
      <Modal
        contentStyleXArray={contentStyleXArray}
        open={localOpen}
        onOpenChange={setLocalOpen}
        trigger={trigger}
        overlayProps={{ onClick: () => setLocalOpen(false) }}
        {...modalProps}
      >
        {/* This exists to prevent Radix from throwing errors and breaking Storybook */}
        <VisuallyHidden>
          <ModalHeader />
        </VisuallyHidden>
        {modalProps.children || children}
      </Modal>
    </ModalDropdownMenuContext.Provider>
  );
};
