"use client";

import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import type { z, ZodSchema } from "zod";

import { useSession } from "../../session";
import type { RequestProps } from "../request";
import { request } from "../request";
import { useHeadersValues } from "./useHeadersValues";

export type UseRequestMutationProps<
  SchemaType extends ZodSchema = any,
  VariablesType = void,
> = Omit<
  Partial<UseMutationOptions<z.TypeOf<SchemaType>, Error, VariablesType>>,
  "mutationFn"
> & {
  mutationFnRequestProps?:
    | RequestProps<SchemaType>
    | ((props: VariablesType) => RequestProps<SchemaType>);
};

export const useRequestMutation = <
  SchemaType extends ZodSchema = any,
  VariablesType = void,
>({
  mutationFnRequestProps,
  ...props
}: UseRequestMutationProps<SchemaType, VariablesType>) => {
  const headersValues = useHeadersValues();
  const { refresh } = useSession();

  return useMutation({
    mutationFn: (props) =>
      request({
        headersValues: headersValues.current,
        method: "POST",
        ...(typeof mutationFnRequestProps === "function"
          ? mutationFnRequestProps(props)
          : mutationFnRequestProps),
        refresh,
      }),
    ...props,
  });
};
