export enum HallowAnalyticsEvent {
  AdminAssigned = "Admin Assigned",
  AudioStartDelayed = "Audio Start Delayed",
  AudioStarted = "Audio Started",
  BibleCompleted = "Bible Completed",
  BibleStarted = "Bible Started",
  ChangedBibleChapter = "Changed Bible Chapter",
  ClosedShareAudioView = "Closed Share Audio View",
  CollectionFavorited = "Collection Favorited",
  CommunityReportResponded = "Community Report Responded",
  CreatedNotreDameEmailAccount = "Created Notre Dame Email Account",
  DonationPurchased = "Donation Purchased",
  DraggedQueueItem = "Dragged Queue Item",
  DraggedTimeSlider = "Dragged Time Slider",
  HeraldPrizeRedemptionClicked = "Herald Prize Redemption Clicked",
  HeraldPrizeRedemptionSubmitted = "Herald Prize Redemption Submitted",
  LoopChanged = "Loop Changed",
  MaximizedPlayer = "Maximized Player",
  MinimizedPlayer = "Minimized Player",
  OpenedBibleBookMenu = "Opened Bible Book Menu",
  OpenedBibleChapterMenu = "Opened Bible Chapter Menu",
  PlayerBeganTrack = "Player Began Track",
  PlayerDestroyed = "Player Destroyed",
  PlayerEndedTrack = "Player Ended Track",
  PlayerInitialized = "Player Initialized",
  PurchasedGiftCard = "Purchased Gift Card",
  RemovedPost = "Removed Post",
  SelectedBibleTranslation = "Selected Bible Translation",
  SelectedChoice = "Selected Choice",
  SubmittedNotreDameForm = "Submitted Notre Dame Form",
  SubscribedNotreDameUser = "Subscribed Notre Dame User",
  TappedAdminDashboardTab = "Tapped Admin Dashboard Tab",
  TappedAssignAsMember = "Tapped Assign As Member",
  TappedAssignAsAdmin = "Tapped Assign As Admin",
  TappedBack = "Tapped Back",
  TappedBibleBook = "Tapped Bible Book",
  TappedBibleChapter = "Tapped Bible Chapter",
  TappedBibleTranslations = "Tapped Bible Translations",
  TappedBuyGift = "Tapped Buy Gift",
  TappedBuyNow = "Tapped Buy Now",
  TappedCampaignOption = "Tapped Campaign Option",
  TappedCampaignSession = "Tapped Campaign Session",
  TappedCancelSubscription = "Tapped Cancel Subscription",
  TappedCommunityMemberOptions = "Tapped Community Member Options",
  TappedCommunityMemberProfile = "Tapped Community Member Profile",
  TappedCommunityPermissionsToggle = "Tapped Community Permissions Toggle",
  TappedCommunitySettings = "Tapped Community Settings",
  TappedContinue = "Tapped Continue",
  TappedContactHallowParishLead = "Tapped Contact Hallow Parish Lead",
  TappedDashboardChartTimeframe = "Tapped Dashboard Chart Timeframe",
  TappedFastForward = "Tapped Fast Forward",
  TappedGroup = "Tapped Group",
  TappedGroupInvite = "Tapped Group Invite",
  TappedGroupMembers = "Tapped Group Members",
  TappedInviteFriends = "Tapped Invite Friends",
  TappedJoinCampaign = "Tapped Join Campaign",
  TappedJoinChallenge = "Tapped Join Challenge",
  TappedLeaveGroup = "Tapped Leave Group",
  TappedLogBackIn = "Tapped Log Back In",
  TappedMakeADonation = "Tapped Make a Donation",
  TappedMergeAccountsSendVerification = "Tapped Merge Accounts Send Verification",
  TappedPause = "Tapped Pause",
  TappedPaymentApplePay = "Tapped Apple Pay",
  TappedPaymentGooglePay = "Tapped Google Pay",
  TappedPaymentPaypal = "Tapped Paypal",
  TappedPlay = "Tapped Play",
  TappedPlayAll = "Tapped Play All",
  TappedPlayNextSession = "Tapped Play Next Session",
  TappedPlayRadioStation = "Tapped Play Radio Station",
  TappedPrayTodaysSession = "Tapped Pray Todays Session",
  TappedPrayerCounterCTA = "Tapped Prayer Counter CTA",
  TappedPrimaryCTA = "Tapped Primary CTA",
  TappedQueue = "Tapped Queue",
  TappedQueueItem = "Tapped Queue Item",
  TappedRefundSubscription = "Tapped Refund Subscription",
  TappedReportReason = "Tapped Report Reason",
  TappedRemoveCommunityMember = "Tapped Remove Community Member",
  TappedRemovePost = "Tapped Remove Post",
  TappedResumeSession = "Tapped Resume Session",
  TappedRewind = "Tapped Rewind",
  TappedSecondaryCTA = "Tapped Secondary CTA",
  TappedSectionActionButton = "Tapped Section Action Button",
  TappedSectionItem = "Tapped Section Item",
  TappedSectionMoreMenu = "Tapped Section More Menu",
  TappedShareCampaign = "Tapped Share Campaign",
  TappedShareCampaignSessionCount = "Tapped Share Campaign Session Count",
  TappedShareChallenge = "Tapped Share Challenge",
  TappedShareCollection = "Tapped Share Collection",
  TappedShuffle = "Tapped Shuffle",
  TappedSkip = "Tapped Skip",
  TappedSkipBack = "Tapped Skip Back",
  TappedSkipForward = "Tapped Skip Forward",
  TappedSkipIntro = "Tapped Skip Intro",
  TappedSleepTimer = "Tapped Sleep Timer",
  TappedTextOnScreen = "Tapped Text on Screen",
  TappedTrackSettings = "Tapped Track Settings",
  TappedUnlinkSmallGroup = "Tapped Unlink Small Group",
  TappedWebAppNav = "Tapped Web App Nav",
  TappedWebCommunity = "Tapped Web Community",
  TappedWebCommunityDropdown = "Tapped Web Community Dropdown",
  TimerChanged = "Timer Changed",
  TimerEnded = "Timer Ended",
  VerifiedNotreDameCaptcha = "VerifiedNotreDameCaptcha",
  ViewedCancelSubscription = "Viewed Cancel Subscription",
  ViewedNotreDameRegister = "Viewed Notre Dame Register",
  ViewedRefundSubscription = "Viewed Refund Subscription",
  ViewedScreen = "Viewed Screen",
  ViewedShareChallengeDialog = "Viewed Share Challenge Dialog",
  ViewedShareCollectionDialog = "Viewed Share Collection Dialog",
}
