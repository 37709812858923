"use client";

import { useTranslations } from "@packages/i18n";
import { usePlayer } from "@packages/media";
import type { MouseEventHandler } from "react";
import { forwardRef, useCallback } from "react";

import type { ButtonProps } from "../../../../../components";
import { PauseIcon, PlayIcon } from "../../../../../components";
import { coreMediaStyles, useMediaClass } from "../../../../utils";
import type { MediaButtonProps } from "../MediaButton";
import { MediaButton } from "../MediaButton";

export type PlayPauseProps = Omit<ButtonProps, "size" | "color"> & {
  size?: MediaButtonProps["size"];
};

export const PlayPause = forwardRef<HTMLButtonElement, PlayPauseProps>(
  ({ size: buttonSize = null, ...props }, ref) => {
    const player = usePlayer();
    const t = useTranslations();
    const mediaClass = useMediaClass();

    const handlePlayPause = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (e) => {
        e.stopPropagation();
        if (player?.paused) {
          player.play();
        } else {
          player?.pause();
        }
      },
      [player],
    );

    return (
      <MediaButton
        largeIcon={
          !player || player.paused ? <PlayIcon.XL /> : <PauseIcon.XL />
        }
        smallIcon={
          !player || player.paused ? <PlayIcon.Medium /> : <PauseIcon.Medium />
        }
        size={buttonSize}
        onClickCapture={handlePlayPause}
        styleXArray={[
          coreMediaStyles.playbackButton,
          buttonSize === "l" ||
          mediaClass.includes("expanded") ||
          mediaClass.includes("fullscreen")
            ? coreMediaStyles.playbackButtonL
            : null,
        ]}
        ref={ref}
        {...props}
        title={t(
          !player || player.paused
            ? "button_description_play"
            : "button_description_pause",
        )}
      />
    );
  },
);

PlayPause.displayName = "PlayPause";
