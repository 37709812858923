import type { BaseKeyFunction, CommunityType } from "../../../../lib";

export type KeyCommunitiesChildrenProps = {
  id: number;
  type: CommunityType;
};

export const keyCommunitiesChildren: BaseKeyFunction<
  KeyCommunitiesChildrenProps
> = ({ id, type }) => ["communities-children", id.toString(), type];
