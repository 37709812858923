import { MIN_WIDTH_TABLET } from "@packages/sdk";
import { useMedia } from "react-use";

export type UseDynamicViewportTablet = {
  defaultState?: boolean;
};

export const useDynamicViewportTablet = ({
  defaultState = false,
}: UseDynamicViewportTablet = {}) =>
  useMedia(`(min-width: ${MIN_WIDTH_TABLET}px)`, defaultState);
