import { homeSchema } from "../../../lib";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export type RequestHomeProps = Omit<RequestProps, "schema" | "url">;

export const requestHome = ({ ...props }: RequestHomeProps = {}) =>
  request({
    ...props,
    schema: homeSchema,
    urlBackendPath: "/home",
  });
