"use client";

import { useQueryClient } from "@tanstack/react-query";
import { useLocale } from "next-intl";

import type { UseRequestMutationProps } from "../../../../../request";
import { useRequestMutation } from "../../../../../request";
import { keyMe } from "../../../me";
import { keyCommunityMember, keyCommunityMembers } from "../../members";
import { keyCommunitiesAdminCommunities } from "../keyCommunitiesAdminCommunities";
import { keyCommunitiesAdminCommunity } from "../keyCommunitiesAdminCommunity";

export type UseRequestCommunitiesAdminMemberProps = {
  communityId: number;
  userId: number;
  mutationProps?: UseRequestMutationProps;
};

export const useRequestCommunitiesAdminMember = ({
  communityId,
  userId,
  mutationProps = {},
}: UseRequestCommunitiesAdminMemberProps) => {
  const locale = useLocale();
  const queryClient = useQueryClient();

  return {
    mutationDelete: useRequestMutation({
      ...mutationProps,
      mutationFnRequestProps: () => ({
        method: "DELETE",
        urlBackendPath: `/communities/${communityId}/members/${userId}`,
      }),
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [keyCommunitiesAdminCommunity({ id: communityId }), locale],
        });
        queryClient.invalidateQueries({
          queryKey: [keyCommunitiesAdminCommunities(), locale],
        });
        queryClient.invalidateQueries({
          queryKey: [keyCommunityMembers({ id: communityId }), locale],
        });
        queryClient.invalidateQueries({ queryKey: [keyMe(), locale] });
        mutationProps?.onSuccess(data, variables, context);
      },
    }),
    mutationPostAdminGrant: useRequestMutation({
      ...mutationProps,
      mutationFnRequestProps: () => ({
        urlBackendPath: `/me/communities/${communityId}/admin/${userId}/grant`,
      }),
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [keyCommunityMembers({ id: communityId }), locale],
        });
        queryClient.invalidateQueries({
          queryKey: [keyCommunityMember({ communityId, userId }), locale],
        });
        mutationProps?.onSuccess(data, variables, context);
      },
    }),
    mutationPostAdminRevoke: useRequestMutation({
      ...mutationProps,
      mutationFnRequestProps: () => ({
        urlBackendPath: `/me/communities/${communityId}/admin/${userId}/revoke`,
      }),
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [keyCommunityMembers({ id: communityId }), locale],
        });
        queryClient.invalidateQueries({
          queryKey: [keyCommunityMember({ communityId, userId }), locale],
        });
        queryClient.invalidateQueries({ queryKey: [keyMe(), locale] });
        mutationProps?.onSuccess(data, variables, context);
      },
    }),
  };
};
