import type { Geolocation } from "@packages/sdk";

export type GetGeolocationStrictConsentPermissionsProps = {
  geolocation: Geolocation;
};

export const getGeolocationStrictConsentPermissions = ({
  geolocation,
}: GetGeolocationStrictConsentPermissionsProps) => {
  if (geolocation.continent === "EU") return true;

  /** Brazil, South Africa */
  if (["BR", "ZA"].includes(geolocation.country)) return true;

  /** California */
  if (["CA"].includes(geolocation.regionCode)) return true;

  return false;
};
