"use client";

import { useEffect, useRef } from "react";

export type UseRefScrollToProps = {
  dependencies: Array<any>;
  delay?: number;
  options?: ScrollToOptions;
};

export const useRefScrollTo = <T extends HTMLElement = HTMLDivElement>({
  dependencies,
  delay = 0,
  options = { behavior: "smooth", top: 0 },
}: UseRefScrollToProps) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current.scrollTop !== 0)
      setTimeout(() => ref.current.scrollTo(options), delay);
  }, dependencies);

  return ref;
};
