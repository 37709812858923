const SST_URL_SELF = (defaultValue: string) => {
  if (!process.env.SST_STAGE && !process.env.SST_RESOURCE_App)
    return defaultValue ?? "";

  let stage = process.env.SST_STAGE;
  if (!stage) {
    try {
      const json = JSON.parse(process.env.SST_RESOURCE_App);
      stage = json.stage;
    } catch (e) {
      return defaultValue ?? "";
    }
  }

  if (/pr\d+/.test(stage)) {
    return `https://${stage}-preview.hallow.com`;
  }
  if (stage === "rc") return "https://rc.hallow.com";
  return defaultValue ?? "";
};

export const URL_SELF =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" &&
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF !== "dev"
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : SST_URL_SELF(process.env.NEXT_PUBLIC_SELF_URL);
