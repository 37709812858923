"use client";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useConsent } from "@packages/consent";
import { useTranslations } from "@packages/i18n";
import type { PasswordAuthFlowForm } from "@packages/sdk";
import { passwordAuthFlowSchema, useRequestAuth } from "@packages/sdk";
import { useRef, useState } from "react";

import { useForm, zodResolver } from "../../..";
import { Button, EyeIcon, IconButton, Text, useAlert } from "../../_base";
import { FormField } from "../forms";
import { EmailHeader } from "./AuthFlowHeader";
import { AuthForm } from "./AuthForm";
import { AuthSubmitButton } from "./AuthSubmitButton";
import { AuthTextInput } from "./AuthTextInput";
import type { AuthFlowProps } from "./AuthTypes";

const PasswordVisibilityButton = ({
  onClick,
  visible,
}: {
  onClick: () => void;
  visible: boolean;
}) => {
  return (
    <IconButton
      icon={visible ? <EyeIcon.Off /> : <EyeIcon.On />}
      onClick={onClick}
      type="button"
      size="m"
      variant="transparentPrimary"
    />
  );
};

export const PasswordAuthFlow = ({
  onAuth,
  setFlow,
  state,
  utm,
  onFormSubmit,
  onCaptchaVerify: reportVerification,
  onError,
  styleXArray = [],
  formDisabled = false,
}: AuthFlowProps) => {
  const t = useTranslations();
  const { consent } = useConsent();
  const alert = useAlert();
  const { mutationRegister, mutationLogin, mutationForgotPassword } =
    useRequestAuth();
  const [visible, setVisible] = useState(false);
  const captchaRef = useRef(null);
  const { email, exists } = state;

  const form = useForm<PasswordAuthFlowForm>({
    resolver: zodResolver(passwordAuthFlowSchema),
  });

  const onSubmit = async ({ password }: PasswordAuthFlowForm) => {
    onFormSubmit?.({ email, ...utm });
    try {
      // If email registration, check captcha value
      if (!exists) captchaRef.current.execute();
      else {
        const data = await mutationLogin.mutateAsync({ email, password });
        await onAuth?.("email", data);
      }
    } catch (error) {
      onError?.(error);
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  const onCaptchaVerify = async (captcha: string) => {
    const { password } = form.getValues();

    reportVerification?.({ email, ...utm });

    try {
      const data = await mutationRegister.mutateAsync({
        email,
        password,
        captcha,
        ...utm,
        consent,
      });
      await onAuth?.("email", data);
    } catch (error) {
      onError?.(error);
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  const onCaptchaError = () => {
    onError?.(null);
    alert.show({
      title: t("general_word_error"),
      description: t("error_registration_failure"),
    });
  };

  const handlePasswordReset = async () => {
    try {
      await mutationForgotPassword.mutateAsync({ email });

      alert.show({
        title: t("general_word_success_exclamation"),
        description: t("dialog_reset_password_email_sent_message", {
          0: email,
        }),
      });
    } catch (error) {
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  return (
    <AuthForm<PasswordAuthFlowForm>
      form={form}
      onSubmit={onSubmit}
      styleXArray={styleXArray}
    >
      {(form, disabled) => (
        <>
          <EmailHeader
            title={t(exists ? "login_title" : "auth_sign_up_title")}
            onBack={() => setFlow("email")}
          />
          <Text type="headline" size="m" as="h2">
            {t(
              exists
                ? "password_input_headline_existing"
                : "auth_welcome_to_new_account",
            )}
          </Text>
          <input type="hidden" name="email" value={email} />
          <FormField
            control={form.control}
            name="password"
            showErrorMessage={false}
            defaultValue={""}
            render={({ field }) => (
              <>
                <AuthTextInput
                  type={visible ? "text" : "password"}
                  required
                  autoFocus
                  autoCorrect="off"
                  autoComplete={exists ? "current-password" : "new-password"}
                  placeholder={t(
                    exists
                      ? "auth_enter_login_password"
                      : "auth_create_your_password",
                  )}
                  button={
                    <PasswordVisibilityButton
                      visible={visible}
                      onClick={() => setVisible(!visible)}
                    />
                  }
                  disabled={formDisabled}
                  {...field}
                />

                <HCaptcha
                  onError={onCaptchaError}
                  onVerify={onCaptchaVerify}
                  ref={captchaRef}
                  sitekey={process.env.NEXT_PUBLIC_HCAPTCHA_KEY}
                  size="invisible"
                />
              </>
            )}
          />
          <AuthSubmitButton disabled={disabled || formDisabled}>
            {t(exists ? "login_title" : "registration_button_text")}
          </AuthSubmitButton>
          {exists && (
            <Button
              isFullWidth
              size="m"
              variant="transparentPrimary"
              type="button"
              onClick={() => handlePasswordReset()}
            >
              {t("password_input_help_option1")}
            </Button>
          )}
        </>
      )}
    </AuthForm>
  );
};

PasswordAuthFlow.displayName = "PasswordAuthFlow";
