"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import { getContentId, useCurrentQueueItem, usePlayer } from "@packages/media";

import { ModalTab, TimerIcon } from "../../../../../components";
import { SleepTimerTab } from "../SleepTimer";

export const SelectTimerTab = () => {
  const player = usePlayer();
  const t = useTranslations();
  const analytics = useAnalytics();
  const { currentItem } = useCurrentQueueItem();

  if (
    player?.sleepTimerTarget &&
    player.sleepTimerTarget > 0 &&
    player.sleepTimerTarget > Date.now() &&
    player?.sleepTimerStart &&
    player.sleepTimerStart > 0
  ) {
    return (
      <SleepTimerTab
        key={"mediaMenu_selectTimer_active"}
        label={t("track_settings_timer")}
        countdownSeconds={
          (player.sleepTimerTarget - player.sleepTimerStart) / 1000
        }
        timeMinStr={t("sleep_length_minutes_format", {
          "0": Math.ceil((player.sleepTimerTarget - Date.now()) / 1000 / 60),
        })}
        value={`${Math.ceil((player.sleepTimerTarget - player.sleepTimerStart) / 1000)}_countdown`}
      />
    );
  }

  return (
    <ModalTab
      label={t("track_settings_timer")}
      key={"timerSelectMenuTab"}
      value={"timerSelectMenu"}
      startIcon={<TimerIcon.Outline />}
      onClickCapture={() =>
        analytics.track({
          event: HallowAnalyticsEvent.TappedSleepTimer,
          properties: {
            content_id: getContentId(currentItem),
            content_type: currentItem.type,
          },
        })
      }
    >
      {t("web_audio_setting_default_timer_none")}
    </ModalTab>
  );
};
