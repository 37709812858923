import { z } from "zod";

import { onboardingStepTypeEnum } from "../enums";

export const onboardingStepSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: onboardingStepTypeEnum,
});

export type OnboardingStep = z.TypeOf<typeof onboardingStepSchema>;
