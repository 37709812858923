import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const COOKIE_KEY_WORD_SPACING = "hlwWordSpacing";

export type DeleteCookieWordSpacingProps = Omit<DeleteCookieProps, "key">;

export const deleteCookieWordSpacing = ({
  cookies,
}: DeleteCookieWordSpacingProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_WORD_SPACING, cookies });

export type GetCookieWordSpacingProps = Omit<GetCookieProps, "key">;

export const getCookieWordSpacing = ({
  cookies,
  schema,
}: GetCookieWordSpacingProps = {}) =>
  getCookie({ key: COOKIE_KEY_WORD_SPACING, cookies, schema });

export type SetCookieWordSpacingProps = Omit<SetCookieProps, "key">;

export const setCookieWordSpacing = ({
  cookies,
  value,
  domain,
  options,
}: SetCookieWordSpacingProps) =>
  setCookie({
    key: COOKIE_KEY_WORD_SPACING,
    cookies,
    value,
    domain,
    options,
  });
