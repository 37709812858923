"use client";

import { z } from "zod";

import { useRequestMutation } from "../../../request";

type UseRequestPhoneOtpVariables = { phone: string };

const requestOtpSchema = z.object({
  exists: z.boolean(),
});

export const useRequestOtp = () => ({
  mutationPost: useRequestMutation({
    mutationFnRequestProps: (data: UseRequestPhoneOtpVariables) => ({
      body: JSON.stringify(data),
      urlBackendPath: "/otp",
      schema: requestOtpSchema,
      requestType: "default",
    }),
  }),
});
