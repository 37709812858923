import type { Guide } from "@packages/sdk";

import { ModalDropdownMenuItem } from "../../../../../components";
import { GuideInfo } from "../../data";

export type SelectGuideListProps = {
  selectedGuide: Guide | undefined;
  onGuideSelection: (guide: Guide) => void;
  options: Guide[];
  videoById: Record<number, boolean>;
};

export const SelectGuideList = ({
  options,
  selectedGuide,
  onGuideSelection,
  videoById,
}: SelectGuideListProps) => {
  return (
    <>
      {(options ?? []).map((option) => (
        <ModalDropdownMenuItem
          key={`sessionDetails_selectGuide_guide_${option.id}`}
          selected={option.id === selectedGuide?.id}
          onSelect={() => onGuideSelection(option)}
        >
          <GuideInfo guide={option} guideHasVideo={videoById[option.id]} />
        </ModalDropdownMenuItem>
      ))}
    </>
  );
};
