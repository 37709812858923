import type { ReactNode } from "react";

import type { WithStylexArray } from "../../../types";
import { Button } from "../../_base";

type AuthSubmitProps = WithStylexArray<{
  disabled: boolean;
  children: ReactNode;
}>;

export const AuthSubmitButton = ({
  disabled,
  children,
  styleXArray,
}: AuthSubmitProps) => {
  return (
    <Button
      type="submit"
      disabled={disabled}
      isFullWidth
      size="l"
      styleXArray={styleXArray}
    >
      {children}
    </Button>
  );
};
