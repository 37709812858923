import { donationIntentSchema } from "../../../../lib";
import { useRequestMutation } from "../../../../request";

export type UseRequestDonationIntentProps = {
  communityId: number;
  campaignId: number;
};

export const useRequestDonationIntent = ({
  communityId,
  campaignId,
}: UseRequestDonationIntentProps) => {
  return {
    mutationPost: useRequestMutation({
      mutationFnRequestProps: ({
        amount,
        anonymous,
      }: {
        amount: number;
        anonymous: boolean;
      }) => ({
        urlBackendPath: `/me/communities/${communityId}/campaigns/${campaignId}/donate`,
        method: "POST",
        requestType: "authenticated",
        schema: donationIntentSchema,
        body: JSON.stringify({
          send_anonymous: anonymous,
          amount_in_cents: amount * 100,
          note_of_support: null,
          currency: "USD",
        }),
      }),
      mutationKey: [communityId, campaignId],
    }),
  };
};
