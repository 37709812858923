import { useState } from "react";
import { v4 as uuid } from "uuid";

import { useTransition } from "../../../../../providers";
import type { StyleXArray } from "../../../../../types";
import type { TransitionKind } from "../Transition";
import { fade } from "./fade";
import { shape } from "./shape";
import { composeSlide, cover, getSlidePhase, push } from "./slide";
import {
  gradientComposer,
  maskPositionComposer,
  maskSizeComposer,
  wipe,
} from "./wipe";

export const useTransitionStyles = (kindProps: TransitionKind) => {
  const [uniqueTransitionId] = useState<string>(uuid());
  const { transitionMs } = useTransition();

  let baseStyleXArray: StyleXArray,
    enteringStyleXArray: StyleXArray,
    enteredStyleXArray: StyleXArray,
    exitingStyleXArray: StyleXArray,
    exitedStyleXArray: StyleXArray;
  switch (kindProps.kind) {
    case "push":
      baseStyleXArray = [
        push.base(
          composeSlide(kindProps.direction, getSlidePhase("push", "base")),
        ),
      ];
      enteringStyleXArray = [
        push.entering(
          composeSlide(kindProps.direction, getSlidePhase("push", "entering")),
        ),
      ];
      enteredStyleXArray = [
        push.entered(
          composeSlide(kindProps.direction, getSlidePhase("push", "entered")),
        ),
      ];
      exitingStyleXArray = [
        push.exiting(
          composeSlide(kindProps.direction, getSlidePhase("push", "exiting")),
        ),
      ];
      exitedStyleXArray = [
        push.exited(
          composeSlide(kindProps.direction, getSlidePhase("push", "exited")),
        ),
      ];
      break;
    case "cover":
      baseStyleXArray = [
        cover.base(
          composeSlide(kindProps.direction, getSlidePhase("cover", "base")),
        ),
      ];
      enteringStyleXArray = [
        cover.entering(
          composeSlide(kindProps.direction, getSlidePhase("cover", "entering")),
        ),
      ];
      enteredStyleXArray = [
        cover.entered(
          composeSlide(kindProps.direction, getSlidePhase("cover", "entered")),
        ),
      ];
      exitingStyleXArray = [cover.exit];
      exitedStyleXArray = [cover.exit];

      break;
    case "wipe":
      baseStyleXArray = [wipe.base];
      enteringStyleXArray = [
        wipe.entering({
          maskImage: gradientComposer(kindProps.angle),
          maskSize: maskSizeComposer(kindProps.angle),
          maskPosition: maskPositionComposer(kindProps.angle, "start"),
        }),
      ];
      enteredStyleXArray = [
        wipe.entered({
          maskImage: gradientComposer(kindProps.angle),
          maskSize: maskSizeComposer(kindProps.angle),
          maskPosition: maskPositionComposer(kindProps.angle, "end"),
        }),
      ];
      exitingStyleXArray = [];
      exitedStyleXArray = [];
      break;
    case "shape":
      baseStyleXArray = [];
      enteringStyleXArray = [];
      enteredStyleXArray = [];
      exitingStyleXArray = [shape.exiting];
      exitedStyleXArray = [
        shape.exited(kindProps.shape, transitionMs, uniqueTransitionId),
      ];
      break;
    case "custom":
      baseStyleXArray = kindProps.baseStyleXArray ?? [];
      enteringStyleXArray = kindProps.enteringStyleXArray;
      enteredStyleXArray = kindProps.enteredStyleXArray;
      exitingStyleXArray = kindProps.exitingStyleXArray;
      exitedStyleXArray = kindProps.exitedStyleXArray;
      break;
    case "fade":
    default:
      baseStyleXArray = [fade.base];
      enteringStyleXArray = [fade.entering];
      enteredStyleXArray = [fade.entered];
      exitingStyleXArray = [fade.exiting];
      exitedStyleXArray = [fade.exited];
  }

  return [
    baseStyleXArray,
    enteringStyleXArray,
    enteredStyleXArray,
    exitingStyleXArray,
    exitedStyleXArray,
  ];
};
