import type { RequestProps } from "../../../request";
import { request } from "../../../request";
import { sessionRefreshSchema } from "../../types";

export type RequestSessionRefreshProps = Omit<
  Partial<RequestProps>,
  "schema" | "url"
> & {
  refreshToken?: string;
};

export const requestSessionRefresh = (
  { refreshToken, ...props }: RequestSessionRefreshProps = {
    refreshToken: null,
  },
) =>
  request({
    ...props,
    body: refreshToken ? JSON.stringify({ refresh_token: refreshToken }) : null,
    method: "POST",
    requestType: "default",
    schema: sessionRefreshSchema,
    urlBackendPath: "/oauth/refresh",
  });
