import type { MediaPlaylist } from "hls.js";

const isTextTrack = (arg: any): arg is TextTrack => !!arg.language;
const isMediaPlaylist = (arg: any): arg is MediaPlaylist => !!arg.lang;

export class MediaTextTrack {
  private readonly asTextTrack: TextTrack;
  private readonly asMediaPlaylist: MediaPlaylist;

  constructor(textTrack: TextTrack);
  constructor(mediaPlaylist: MediaPlaylist);
  constructor(arg: TextTrack | MediaPlaylist) {
    if (isTextTrack(arg)) this.asTextTrack = arg;
    else this.asTextTrack = null;
    if (isMediaPlaylist(arg)) this.asMediaPlaylist = arg;
    else this.asMediaPlaylist = null;
  }

  get language() {
    return this.asTextTrack?.language ?? this.asMediaPlaylist?.lang;
  }

  get id() {
    return this.asTextTrack?.id ?? this.asMediaPlaylist?.id;
  }
}
