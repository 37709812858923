"use client";

import { useTranslations } from "@packages/i18n";
import { usePlayer } from "@packages/media";

import { ModalTab, MusicIcon } from "../../../../../components";
import type { MediaMenuTabProps } from "../MediaMenu";

export const SelectBackgroundTab = ({ disabled }: MediaMenuTabProps) => {
  const t = useTranslations();
  const player = usePlayer();

  return (
    <ModalTab
      key={"selectBgMenuTab"}
      value={"selectBgMenu"}
      label={t("track_settings_background_sounds")}
      startIcon={<MusicIcon />}
      disabled={disabled}
    >
      {player?.bgTitle ?? t("web_audio_setting_list_option_background_none")}
    </ModalTab>
  );
};
