import { URL_ACCESS } from "../constants";
import type { HallowQueries } from "../types";
import { appendQueries } from "./appendQueries";

export const openAppDownloads = async () => {
  window.location.href = `https://hallow.com/downloads`;
};

export const openAppFavorites = async () => {
  window.location.href = `https://hallow.com/favorites`;
};

export const redirectToAuth = async (
  flowPath = "",
  queries: HallowQueries = {},
) => {
  window.location.href = `${URL_ACCESS}${flowPath}?loginRedirect=${encodeURIComponent(window.location.href)}${appendQueries(queries)}`;
};

export const openEmail = ({
  email,
  subject = "",
  body = "",
}: {
  email: string;
  subject?: string;
  body?: string;
}) => {
  const mailtoLink = `mailto:${encodeURIComponent(email)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  window.open(mailtoLink, "_blank");
};
