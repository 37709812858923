"use client";

import { useCurrentQueueItem, usePlayer } from "@packages/media";
import { useRedirects, useRequestMe } from "@packages/sdk";

import { SelectLengthList } from "./SelectLengthList";

export const SelectLengthContent = () => {
  const { currentItem } = useCurrentQueueItem();
  const { redirectToSubscribe } = useRedirects();
  const { query: me } = useRequestMe();
  const player = usePlayer();
  if (currentItem?.type === "radio_song") return null;

  const lengths = currentItem?.prayer?.tracks?.filter(
    (t) => t.guide_id === currentItem.selected_audio.guide_id,
  );

  return (
    <SelectLengthList
      selectedLength={currentItem?.selected_audio}
      setSelectedLength={(option) => {
        const hasAccess =
          typeof option.has_access !== "undefined"
            ? option.has_access
            : (option.paid && me?.data?.subscription) || !option.paid;
        if (hasAccess) return player.switchTo(option);
        return redirectToSubscribe({ newWindow: true });
      }}
      options={lengths}
    />
  );
};
