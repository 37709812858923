"use client";

import { useCurrentGuide, usePlayer } from "@packages/media";
import type { Track } from "@packages/sdk";

import { getVideoByGuideId } from "./getVideoByGuideId";
import { SelectGuideList } from "./SelectGuideList";

export const SelectGuideContent = () => {
  const { currentItem, guides } = useCurrentGuide();
  const player = usePlayer();

  if (currentItem?.type === "radio_song") return null;

  const options: Track[] = [];
  for (const guide of guides) {
    let closestTimeDiff: number = 1000000;
    let closestTimeTrack: Track | null = null;
    for (const track of currentItem.prayer.tracks) {
      if (track.guide_id !== guide.id) continue;
      const timeDiff = Math.abs(
        track.duration - currentItem.selected_audio.duration,
      );
      if (timeDiff < closestTimeDiff) {
        closestTimeDiff = timeDiff;
        closestTimeTrack = track;
      }
    }
    if (closestTimeTrack) options.push(closestTimeTrack);
  }

  return (
    <SelectGuideList
      key={"mediaMenu_selectGuideList"}
      onGuideSelection={(guide) => {
        const choice = options.find((opt) => opt.guide_id === guide.id);
        return player?.switchTo(choice);
      }}
      options={guides}
      videoById={getVideoByGuideId(currentItem?.prayer?.tracks)}
      selectedGuide={guides.find(
        (g) => g.id === currentItem?.selected_audio?.guide_id,
      )}
    />
  );
};
