import { z } from "zod";

import {
  communityStatusEnum,
  communityTierEnum,
  communityTypeEnum,
} from "../../enums";
import { addressSchema } from "../addressSchema";
import { communityMemberSummarySchema } from "./communityMemberSummarySchema";
import { communitySharingDefaultsSchema } from "./communitySharingDefaultsSchema";

const parentDetailsSchema = z.object({
  id: z.number(),
  image_url: z.string().nullable(),
  color_hex: z.string().nullable(),
  name: z.string(),
  is_member: z.boolean(),
});

const communityContractSchema = z.object({
  id: z.number(),
  package_tier: communityTierEnum,
  start_date: z.string().datetime(),
  code: z.string().nullable(),
});

export const communitySchema = z.object({
  id: z.number(),
  description: z.string().nullable(),
  image_url: z.string().nullable(),
  color_hex: z.string().nullable(),
  address: addressSchema.nullable(),
  name: z.string(),
  status: communityStatusEnum.nullable(),
  launched_at: z.string().datetime().nullable(),
  type: communityTypeEnum,
  created_at: z.string().datetime(),
  updated_at: z.string().datetime(),
  is_non_admin_content_allowed: z.boolean().nullable().optional(),
  address_label: z.string(),
  simple_address_label: z.string(),
  member_summary: communityMemberSummarySchema,
  is_nonadmin_content_allowed: z.boolean(),
  sharing_defaults: communitySharingDefaultsSchema,
  is_admin: z.boolean(),
  has_requested_admin: z.boolean(),
  parent_community_id: z.number().nullable(),
  parent_details: parentDetailsSchema.nullable(),
  tag_text: z.string().nullable(),
  current_contract: communityContractSchema.nullable(),
});

export type Community = z.infer<typeof communitySchema>;
