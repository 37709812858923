"use client";
import type { Locale } from "@packages/sdk";
import { usePathname, useSearchParams } from "next/navigation";
import { IntlErrorCode, NextIntlClientProvider } from "next-intl";
import type { ComponentProps } from "react";
import { Suspense, useEffect } from "react";

import { useRouter } from "../utils";

export type I18nProviderProps = ComponentProps<
  typeof NextIntlClientProvider
> & {
  initialLocale?: Locale;
  isOnErrorMessagesWithUnderscoresOnly?: boolean;
};

export const Internal = ({
  children,
  initialLocale,
  isOnErrorMessagesWithUnderscoresOnly,
  locale,
  ...props
}: I18nProviderProps) => {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  /**
   * Handle errors on messages with underscores ONLY
   */
  if (isOnErrorMessagesWithUnderscoresOnly)
    props.onError = (error) => {
      if (
        error.code === IntlErrorCode.MISSING_MESSAGE &&
        error.originalMessage.includes("_")
      )
        console.error(error);
    };

  useEffect(() => {
    if (initialLocale) {
      if (initialLocale !== locale)
        router.push(
          `${pathname}${searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ""}`,
          { locale: initialLocale },
        );
    }
  }, [initialLocale]);

  return (
    <NextIntlClientProvider locale={locale} timeZone="UTC" {...props}>
      {children}
    </NextIntlClientProvider>
  );
};

export const I18nProvider = ({ children, ...props }: I18nProviderProps) => (
  <Suspense>
    <Internal {...props}>{children}</Internal>
  </Suspense>
);
