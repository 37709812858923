import type { RequestProps } from "../../../request";
import { request } from "../../../request";
import { geolocationSchema } from "./types";

export type RequestGeolocationProps = Omit<RequestProps, "url">;

export const requestGeolocation = ({
  ...props
}: RequestGeolocationProps = {}) =>
  request({
    ...props,
    requestType: "default",
    schema: geolocationSchema,
    urlBackendPath: "/geolocation",
  });
