"use client";

import { useTranslations } from "@packages/i18n";
import { useCurrentQueueItem } from "@packages/media";

import { MediaOptionsIcon, ModalTab } from "../../../../../components";
import type { MediaMenuTabProps } from "../MediaMenu";

export const SelectLengthTab = ({ disabled }: MediaMenuTabProps) => {
  const t = useTranslations();
  const { currentItem } = useCurrentQueueItem();

  return (
    <ModalTab
      key={"lengthSelectMenuTab"}
      value={"lengthSelectMenu"}
      startIcon={<MediaOptionsIcon />}
      label={t("track_settings_length")}
      disabled={disabled}
    >
      {currentItem.selected_audio.length}
    </ModalTab>
  );
};
