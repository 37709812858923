import { z } from "zod";

export const geolocationSchema = z.object({
  city: z.string().optional(),
  continent: z.string().optional(),
  country: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  postalCode: z.string().optional(),
  regionCode: z.string().optional(),
  regionName: z.string().optional(),
});

export type Geolocation = z.infer<typeof geolocationSchema>;
