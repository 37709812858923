"use client";

import { useConsent } from "@packages/consent";
import { useTranslations } from "@packages/i18n";
import type { EmailAuthFlowForm } from "@packages/sdk";
import { emailAuthFlowSchema, useRequestAuth } from "@packages/sdk";

import { Button, Text, useAlert } from "../../_base";
import { FormField } from "../forms";
import { EmailHeader } from "./AuthFlowHeader";
import { AuthForm } from "./AuthForm";
import { AuthSubmitButton } from "./AuthSubmitButton";
import { AuthTextInput } from "./AuthTextInput";
import type { AuthFlowProps } from "./AuthTypes";

export const EmailAuthFlow = ({
  setFlow,
  email,
  state,
  setState,
  allowedMethods,
  customSchema,
  styleXArray = [],
}: AuthFlowProps) => {
  const t = useTranslations();
  const alert = useAlert();
  const { mutationEmailExists } = useRequestAuth();
  const { countryCode } = useConsent();
  const onSubmit = async ({ email }: EmailAuthFlowForm) => {
    try {
      const exists = await mutationEmailExists.mutateAsync({ email });
      setState({ ...state, email, exists });
      setFlow("password");
    } catch (error) {
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  return (
    <AuthForm<EmailAuthFlowForm>
      schema={customSchema ?? emailAuthFlowSchema}
      defaultValues={{ email }}
      onSubmit={onSubmit}
      styleXArray={styleXArray}
    >
      {(form, disabled) => (
        <>
          <EmailHeader onBack={() => setFlow("main")} />
          <Text type="headline" size="m" as="h2">
            {t("email_input_headline")}
          </Text>
          <FormField
            control={form.control}
            name="email"
            showErrorMessage={false}
            defaultValue={""}
            render={({ field }) => (
              <AuthTextInput
                type="email"
                required
                autoFocus
                autoComplete="username"
                autoCorrect="off"
                placeholder={t("email_input_hint")}
                {...field}
              />
            )}
          />
          <AuthSubmitButton disabled={disabled}>
            {t("onboarding_button_continue")}
          </AuthSubmitButton>
          {countryCode === "US" && allowedMethods.includes("phone") && (
            <Button
              isFullWidth
              size="m"
              variant="transparentPrimary"
              onClick={() => setFlow("phone")}
            >
              {t("sms_sign_up_phone_instead")}
            </Button>
          )}
        </>
      )}
    </AuthForm>
  );
};
