import { z } from "zod";

export const emailAuthFlowSchema = z.object({
  email: z.string().email(),
});
export type EmailAuthFlowForm = z.infer<typeof emailAuthFlowSchema>;

export const passwordAuthFlowSchema = z.object({
  password: z.string(),
});
export type PasswordAuthFlowForm = z.infer<typeof passwordAuthFlowSchema>;

export const phoneAuthFlowSchema = z.object({
  phone: z.string(),
});

export const customEmailAuthSchema = (re: RegExp) =>
  z.object({ email: z.string().regex(re) });

export type PhoneAuthFlowForm = z.infer<typeof phoneAuthFlowSchema>;
