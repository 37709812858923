"use client";

import { createFullscreenModal } from "../../../modals";
import type { CampaignSupportFormProps } from "./CampaignSupportForm";
import { CampaignSupportForm } from "./CampaignSupportForm";

export type CampaignSupportModalProps = CampaignSupportFormProps;

export const CampaignSupportModal = createFullscreenModal(
  (props: CampaignSupportModalProps) => <CampaignSupportForm {...props} />,
);
