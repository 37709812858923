"use client";

import type { QueueContentType } from "../../../lib";
import { queueSchema } from "../../../lib";
import type { UseRequestProps } from "../../../request";
import { useRequest } from "../../../request";

export type UseRequestQueueProps = UseRequestProps;

export const useRequestQueue = ({ ...props }: UseRequestQueueProps = {}) => ({
  requestPut: useRequest({
    ...props,
    fnRequestProps: (data: {
      content: Array<{
        content_id: number;
        content_type: QueueContentType;
      }>;
      current_item_index: number;
    }) => ({
      body: JSON.stringify(data),
      method: "PUT",
      schema: queueSchema,
      urlBackendPath: "/queue",
    }),
  }),
});
