"use client";

import { useEffect, useState } from "react";

export type UseCookieStateProps<T = any> = {
  defaultValue?: T;
  key: string;
  maxAge?: number;
  domain?: string;
  path?: string;
  sameSite?: "lax" | "strict";
};

/**
 * Sets a non-HTTP-only cookie for storing values that we might also need on the server.
 * Do NOT use this for values that need to kept secure or in an HTTP-only context.
 */
export const useCookieState = <T = any>({
  defaultValue = null,
  domain,
  key,
  maxAge = 315360000, // 10 years
  path = "/",
  sameSite = "lax",
}: UseCookieStateProps<T>) => {
  const getCookie = (): T | null => {
    if (typeof document !== "undefined") {
      const cookies = document.cookie.split(";");
      for (const cookie of cookies) {
        const [cookieKey, cookieValue] = cookie.split("=");
        if (cookieKey === key) {
          return JSON.parse(cookieValue);
        }
      }
    }

    return defaultValue;
  };

  const setCookie = (value: T) => {
    if (typeof document !== "undefined") {
      document.cookie = `${key}=${encodeURIComponent(JSON.stringify(value))};max-age=${maxAge};domain=${domain ?? (typeof window !== "undefined" ? window.location.hostname : ".hallow.com")};path=${path};samesite=${sameSite}`;
    }
  };

  const [state, setState] = useState<null | T>(defaultValue);

  useEffect(() => {
    setState(getCookie());
  }, []);

  useEffect(() => {
    setCookie(state);
  }, [state]);

  return [state, setState] as const;
};
