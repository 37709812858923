"use client";

import { useTranslations } from "@packages/i18n";
import { SPEEDS, usePlayerSpeedIndex } from "@packages/media";

import { ModalTab, SpeedIcon } from "../../../../../components";
import type { MediaMenuTabProps } from "../MediaMenu";

export const SelectPlaybackSpeedTab = ({ disabled }: MediaMenuTabProps) => {
  const { currentIdx } = usePlayerSpeedIndex();
  const t = useTranslations();

  return (
    <ModalTab
      key={"speedSelectMenuTab"}
      value={"speedSelectMenu"}
      label={t("track_settings_playback_speed")}
      startIcon={<SpeedIcon />}
      disabled={disabled}
    >
      {t("web_audio_player_speed", { "0": SPEEDS[currentIdx] })}
    </ModalTab>
  );
};
