import { z } from "zod";

export const addressSchema = z.object({
  street_address: z.string().nullable(),
  city: z.string().nullable(),
  region: z.string().nullable(),
  postal_code: z.string().nullable(),
  country: z.string().nullable(),
});

export type Address = z.infer<typeof addressSchema>;
