"use client";

import type HLS from "hls.js";
import { forwardRef, useImperativeHandle, useRef } from "react";

import type { HallowElementProps } from "../../../../types";
import { useMediaLoader } from "../../../hooks";

declare global {
  interface Window {
    Hls: typeof HLS;
  }
}

export type VideoProps = HallowElementProps<"video"> & {
  hlsUrl: string;
  policyCookie: string;
  keyPairIdCookie: string;
  signatureCookie: string;
  regularUrl: string;
};

export type HallowVideoElement = { hls: HLS; element: HTMLVideoElement };

export const Video = forwardRef<HallowVideoElement, VideoProps>(
  (
    {
      children,
      hlsUrl,
      policyCookie,
      keyPairIdCookie,
      signatureCookie,
      regularUrl,
      ...props
    },
    ref,
  ) => {
    const mediaRef = useRef<HTMLVideoElement>(null);

    useImperativeHandle(ref, () =>
      mediaRef.current
        ? {
            get hls() {
              return hls.current;
            },
            get element() {
              return mediaRef.current;
            },
          }
        : null,
    );

    const hls = useMediaLoader({
      mediaRef,
      hlsUrl,
      regularUrl,
      policyCookie,
      keyPairIdCookie,
      signatureCookie,
    });

    return (
      <video ref={mediaRef} {...props} playsInline>
        {children}
      </video>
    );
  },
);

Video.displayName = "Video";
