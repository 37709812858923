import type { Track } from "@packages/sdk";

export const getVideoByGuideId = (trackList: Track[]) =>
  trackList.reduce(
    (prev, cur) => {
      if (prev[cur.guide_id]) return prev;
      if (cur.media_type === "video") prev[cur.guide_id] = true;
      return prev;
    },
    {} as Record<number, boolean>,
  );
