"use client";

import { useEffect, useState } from "react";

import { request } from "../../../request";
import { useHeadersValues } from "../../../request/hooks/useHeadersValues";

export const useIsTeacher = () => {
  const headersValues = useHeadersValues();
  const [isTeacher, setIsTeacher] = useState<boolean>(false);

  useEffect(() => {
    request({
      headersValues: headersValues.current,
      urlBackendPath: "/me/teacher-resource",
    })
      .then(() => setIsTeacher(true))
      .catch(() => setIsTeacher(false));
  }, []);

  return isTeacher;
};
