"use client";

import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyGeolocation } from "./keyGeolocation";
import { geolocationSchema } from "./types";

export type UseRequestGeolocationProps = UseRequestQueryProps;

export const useRequestGeolocation = ({
  ...props
}: UseRequestGeolocationProps = {}) => ({
  query: useRequestQuery({
    enabledWithoutSessionToken: true,
    ...props,
    queryFnRequestProps: {
      requestType: "default",
      schema: geolocationSchema,
      urlBackendPath: "/geolocation",
    },
    queryKey: keyGeolocation(),
  }),
});
