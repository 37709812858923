import { useQueryClient } from "@tanstack/react-query";
import { useLocale } from "next-intl";

import { useRequestMutation } from "../../../request";
import { keyCollection } from "../collection";
import { keyHome } from "../home";
import { keyNextUp } from "../nextUp";

export type UseRequestChallengeProps = {
  id: number;
};

export const useRequestChallenge = ({ id }: UseRequestChallengeProps) => {
  const urlBackendPath = `/collections/${id}/users`;

  const locale = useLocale();
  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: [keyHome(), locale] });
    queryClient.invalidateQueries({ queryKey: [keyNextUp(), locale] });
    queryClient.invalidateQueries({
      queryKey: [keyCollection({ id }), locale],
    });
  };

  return {
    mutationDelete: useRequestMutation({
      mutationFnRequestProps: {
        method: "DELETE",
        urlBackendPath,
      },
      onSuccess: () => invalidateQueries(),
    }),
    mutationPost: useRequestMutation({
      mutationFnRequestProps: {
        urlBackendPath,
      },
      onSuccess: () => invalidateQueries(),
    }),
  };
};
