"use client";

import { usePathname } from "next/navigation";
import { useLocale } from "next-intl";

import { pathMatchFn } from "./pathMatchFn";

export const usePathMatch = () => {
  const pathname = usePathname();
  const locale = useLocale();

  return (href: string, exact: boolean = false) =>
    pathMatchFn(href, exact, pathname, locale);
};
