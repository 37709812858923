import type { Community } from "@packages/sdk";
import { CommunityType } from "@packages/sdk";
import type {
  BasicIconProps,
  SidebarProps,
  SidebarTabProps,
} from "@packages/ui";
import {
  ActivityIcon,
  CommunityAltIcon,
  FriendsIcon,
  GivingIcon,
  ReportIcon,
  SettingsAltIcon,
  UpwardTrendIcon,
} from "@packages/ui";

export const PAD_TABS: (Pick<SidebarTabProps, "actionRight" | "message"> & {
  sidebarState: SidebarProps["state"];
  path: string;
  Icon: (props: BasicIconProps) => JSX.Element;
  getHidden?: (community: Community) => boolean;
})[] = [
  {
    Icon: UpwardTrendIcon,
    message: "web_dashboard",
    path: "dashboard",
    sidebarState: "expanded",
  },
  {
    Icon: ActivityIcon,
    //TODO: Modify this condition to include networks once network support is added to PAD
    getHidden: (community) => community.type !== CommunityType.parish,
    message: "intentions_activity",
    path: "activity",
    sidebarState: "expanded",
  },
  {
    Icon: GivingIcon,
    getHidden: (community) => community.type !== CommunityType.parish,
    message: "giving_title",
    path: "giving",
    sidebarState: "expanded",
  },
  {
    Icon: CommunityAltIcon,
    message: "community_detail_groups_screen_title",
    path: "groups",
    sidebarState: "closed",
  },
  {
    Icon: FriendsIcon,
    getHidden: (community) => community.type !== CommunityType.parish,
    message: "community_settings_members",
    path: "members",
    sidebarState: "closed",
  },
  {
    Icon: ReportIcon,
    getHidden: (community) => community.type !== CommunityType.parish,
    message: "parish_admin_dashboard_navigation_reported",
    path: "reported",
    sidebarState: "expanded",
  },
  {
    Icon: SettingsAltIcon,
    message: "settings_title",
    path: "settings",
    sidebarState: "closed",
  },
];

export const padTabsPaths = PAD_TABS.map(({ path }) => path);

export const PAD_SUPPORT_EMAIL = "parishes@hallow.app";

export const PAD_COUNTRY_OPTIONS = [
  {
    value: "US",
    label: "United States",
  },
  {
    value: "BR",
    label: "Brazil",
  },
  {
    value: "DE",
    label: "Germany",
  },
  {
    value: "MX",
    label: "Mexico",
  },
  {
    value: "PH",
    label: "Philippines",
  },
  {
    value: "GB",
    label: "United Kingdom",
  },
];
