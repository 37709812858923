"use client";

import { useConsent } from "@packages/consent";
import { useTranslations } from "@packages/i18n";
import { useRequestAuth } from "@packages/sdk";
import { useGoogleLogin } from "@react-oauth/google";

import { Button, GoogleIcon, useAlert } from "../../_base";
import type { AuthSocialButtonProps } from "./AuthTypes";

export const GoogleAuthButton = ({ onAuth }: AuthSocialButtonProps) => {
  const t = useTranslations();
  const alert = useAlert();
  const { mutationGoogleAuth } = useRequestAuth();
  const { consent } = useConsent();
  const completeGoogleLogin = async (token) => {
    try {
      const auth = await mutationGoogleAuth.mutateAsync({
        token,
        auth_flow: "auth-code",
        consent,
      });
      await onAuth?.("google", auth);
    } catch (error) {
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  const openGoogleDialog = useGoogleLogin({
    flow: "auth-code",
    scope: "profile email",
    onSuccess: (response) => {
      completeGoogleLogin(response.code);
    },
    onError: () => {
      alert.show({
        title: t("general_word_error"),
        description: t("login_social_auth_error_message"),
      });
    },
  });

  const handleClick = () => {
    openGoogleDialog();
  };

  return (
    <Button
      size="l"
      variant="neutral"
      isFullWidth
      startIcon={<GoogleIcon />}
      onClick={handleClick}
    >
      {t("launch_continue_with_google")}
    </Button>
  );
};
