import { z } from "zod";

export const trackSchema = z.object({
  duration: z.number(),
  guide_id: z.number(),
  has_access: z.boolean().optional(),
  has_transcript: z.boolean(),
  id: z.number(),
  length: z.string(),
  media_type: z.enum(["audio", "video"]),
  paid: z.boolean(),
  prayer_id: z.number(),
});

export type Track = z.infer<typeof trackSchema>;
