import {
  articleSchema,
  homeHeroSchema,
  homeMetaSchema,
  menusSchema,
} from "../../../lib";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export type RequestWordpressProps = Partial<
  Omit<RequestProps, "schema" | "urlBackendPath">
> & {
  locale: string;
};

export const requestWordpressMenus = ({
  locale,
  ...props
}: RequestWordpressProps) => {
  const signal = AbortSignal.timeout(10000);
  signal.throwIfAborted();
  return request({
    ...props,
    next: { tags: [`menus`], revalidate: 60 * 60 },
    requestType: "default",
    schema: menusSchema,
    url: `${process.env.NEXT_PUBLIC_WORDPRESS_API}/menus?locale=${locale}`,
    signal,
  });
};

export const requestWordpressArticles = ({
  locale,
  ...props
}: RequestWordpressProps) => {
  const signal = AbortSignal.timeout(10000);
  signal.throwIfAborted();
  return request({
    ...props,
    next: { tags: [`articles`], revalidate: 60 * 60 },
    requestType: "default",
    schema: articleSchema.array(),
    url: `${process.env.NEXT_PUBLIC_WORDPRESS_API}/posts/latest?locale=${locale}`,
    signal,
  });
};

export const requestWordpressMetadata = ({}: any = {}) => {
  const signal = AbortSignal.timeout(10000);
  signal.throwIfAborted();
  return request({
    next: { tags: ["home"], revalidate: 60 * 60 },
    requestType: "default",
    schema: homeMetaSchema,
    url: `${process.env.NEXT_PUBLIC_WORDPRESS_API}/home/meta`,
    signal,
  });
};

export const requestWordpressHero = ({ locale }: RequestWordpressProps) => {
  const signal = AbortSignal.timeout(10000);
  signal.throwIfAborted();
  return request({
    next: { tags: ["home"], revalidate: 60 * 60 },
    requestType: "default",
    schema: homeHeroSchema,
    url: `${process.env.NEXT_PUBLIC_WORDPRESS_API}/home/hero?locale=${locale}`,
    signal,
  });
};
