"use client";

import type { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";

import type { UseCookieStateProps } from "./useCookieState";
import { useCookieState } from "./useCookieState";
import { useLocalStorageState } from "./useLocalStorageState";

export type UseLegacyLocalStateProps<T> = UseCookieStateProps<T> & {
  legacyLocalStorageKey?: string;
  headerValue?: T;
};

export const useLegacyLocalState = <T = any>({
  key,
  legacyLocalStorageKey = key,
  maxAge,
  domain,
  path,
  sameSite,
  defaultValue,
  headerValue,
}: UseLegacyLocalStateProps<T>): [T, Dispatch<SetStateAction<T>>] => {
  const [legacyState] = useLocalStorageState<T>({ key: legacyLocalStorageKey });
  const [cookieState, setCookieState] = useCookieState<T>({
    key,
    maxAge,
    domain,
    path,
    sameSite,
    defaultValue: headerValue ?? legacyState ?? defaultValue,
  });

  useEffect(() => {
    if (legacyState && cookieState && typeof localStorage !== "undefined") {
      localStorage.removeItem(legacyLocalStorageKey);
    }
  }, [legacyState, cookieState, legacyLocalStorageKey]);

  return [cookieState, setCookieState];
};
