"use client";

import {
  COOKIE_KEY_AJS_ANONYMOUS_ID,
  COOKIE_KEY_HLW_CONSENT,
  useCookie,
  useRequestGeolocation,
  useRequestMe,
  useSession,
  z,
} from "@packages/sdk";
import type { ComponentProps } from "react";
import { useEffect, useState } from "react";

import type { ConsentContext } from "../lib";
import {
  consentContext,
  DEFAULT_CONSENT_OPTIONS,
  DEFAULT_CONSENT_OPTIONS_STRICT,
} from "../lib";
import { Consent } from "../types";
import { getGeolocationStrictConsentPermissions } from "../utils";

export type ConsentProviderProps = Omit<
  ComponentProps<typeof consentContext.Provider>,
  "value"
>;

export const ConsentProvider = ({
  children,
  ...props
}: ConsentProviderProps) => {
  const [ajsAnonymousId, setAjsAnonymousId] = useCookie({
    key: COOKIE_KEY_AJS_ANONYMOUS_ID,
  });
  const [consent, setConsent] = useCookie<number[]>({
    key: COOKIE_KEY_HLW_CONSENT,
    schema: z.number().array(),
  });
  const [countryCode, setCountryCode] = useState<string | null>("US");
  const { query: queryGeolocation } = useRequestGeolocation();
  const { mutationPut: mutationPutMe, query: queryMe } = useRequestMe();
  const { status } = useSession();

  const isConsenting = consent ? consent.includes(Consent.ANALYTICS) : false;
  const isLoading = !consent;

  /** TODO: remove/improve when ConsentModal/CookieManager are improved */
  const [isConsentModalVisible, setIsConsentModalVisible] = useState(false);
  const saveConsent = async ({
    consent,
  }: {
    consent: ConsentContext["consent"];
  }) => {
    setConsent(consent);
    if (status === "authenticated") {
      await mutationPutMe.mutateAsync({ consent });
    }
  };

  /**
   * If the user does not have consent preferences,
   * default: use DEFAULT_CONSENT_OPTIONS
   * strict IP: use DEFAULT_CONSENT_OPTIONS_STRICT
   * strict IP: show consent modal
   */
  useEffect(() => {
    const handleConsentFromGeolocation = async () => {
      if (queryGeolocation.data) {
        const isGeolocationStrictConsentPermissions =
          getGeolocationStrictConsentPermissions({
            geolocation: queryGeolocation.data,
          });

        setConsent(
          !isGeolocationStrictConsentPermissions
            ? DEFAULT_CONSENT_OPTIONS
            : DEFAULT_CONSENT_OPTIONS_STRICT,
        );
        setCountryCode(queryGeolocation.data.country);
        setIsConsentModalVisible(
          !isGeolocationStrictConsentPermissions ? false : true,
        );
        /** Default configuration */
      } else if (!queryGeolocation.isLoading) {
        const isLocalDevelopment = process.env.NODE_ENV === "development";
        setConsent(
          isLocalDevelopment
            ? DEFAULT_CONSENT_OPTIONS
            : DEFAULT_CONSENT_OPTIONS_STRICT,
        );
        setIsConsentModalVisible(!isLocalDevelopment);
      }
    };

    if (!consent?.length) handleConsentFromGeolocation();
  }, [consent, queryGeolocation.data]);

  /** Sync any inconsistent consents */
  useEffect(() => {
    if (queryMe.data?.consent) {
      if (
        (consent &&
          JSON.stringify(consent?.sort()) !==
            JSON.stringify(queryMe.data.consent.sort())) ||
        !consent
      ) {
        setConsent(queryMe.data.consent);
      }
    } else if (consent && status === "authenticated") {
      mutationPutMe.mutate({ consent });
    }
  }, [consent, queryMe.data?.consent]);

  return (
    <consentContext.Provider
      value={{
        ajsAnonymousId,
        consent,
        isConsenting,
        isLoading,
        isConsentModalVisible,
        countryCode,
        saveConsent,
        setAjsAnonymousId,
        setConsent,
        setIsConsentModalVisible,
      }}
      {...props}
    >
      {children}
    </consentContext.Provider>
  );
};
